

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { API_SignUp, API_OAuth, API_SignIn, API_POST_Verify, API_POST_ForgetPwd } from '../../global/constants';
import { checkAuthToken } from "../../global/TokenUtil";
function Header() {
    var history = useHistory();
    const [loginStr, setloginStr] = useState('');
    const [islogin, setlogin] = useState(false);
    const [AcntNo, setAcntNo] = useState('');
    const [AcntPwd, setAcntPwd] = useState('');
    const [AcntRePwd, setAcntRePwd] = useState('');
    const [Cky, setCky] = useState('');
    const [AcntName, setAcntName] = useState('');
    const [sub, setSub] = useState('');
    const [subType, setSubType] = useState('');
    const path = window.location.pathname;
    const [UserData, setUserData] = useState();
    const [UB, setUB] = useState();
    const [UserPhone, setUserPhone] = useState();
    const [BirYearList, setBirYearList] = useState();
    const [BirMonthList, setBirMonthList] = useState();
    const [BirYear, setBirYear] = useState();
    const [BirMonth, setBirMonth] = useState();
    const [Verify, setVerify] = useState();

    //忘記密碼
    const [ForgetAcntNo, setForgetAcntNo] = useState('');
    const [ForgetAcntPwd, setForgetAcntPwd] = useState('');
    const [ForgetAcntRePwd, setForgetAcntRePwd] = useState('');
    const [ForgetVerify, setForgetVerify] = useState();

    useEffect(() => {
        setUserData(localStorage.getItem("UserData"));
        setUB(localStorage.getItem("UB"));
        const myDate = new Date();
        const currYear = myDate.getFullYear(); //西元年
        //console.log(currYear);

        let yearLists = [];
        for (let i = 1940; i <= currYear; i++) {
            //記得在JSX中使用JS變數要用花括號包著
            yearLists.push(<option value={i}>{i}</option>);
        }
        setBirYearList(yearLists);

        let monthLists = [];
        for (let j = 1; j <= 12; j++) {
            monthLists.push(<option value={j}>{j}</option>);
        }
        setBirMonthList(monthLists);

    }, []);

    function showSignup() {
        var s = document.getElementById('sys_signin');
        s.click();
    }

    //Google登入
    function OAuthLogin(token) {
        var dt =
        {
            AcntToken: token
        }
        //console.log(token);
        
        //第三方登入
        API_OAuth(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //alert("登入成功");
                    //localStorage.setItem("UserData", JSON.stringify(data.result));
                    //window.location = "/";
                    checkAuthToken(data);

                    localStorage.setItem('UserData', JSON.stringify(data.result));
                    alert("登入成功");
                    
                    var s = document.getElementById('sys_signin');
                    s.click();
                    setUserData(JSON.stringify(data.result));

                    localStorage.setItem('UB', JSON.stringify(data.result.ub));
                    //console.log(JSON.stringify(data.result.ub));
                    setUB(JSON.stringify(data.result.ub));
                }
                else if (data.code = "1036") {
                    setAcntName(data.result.name);
                    setAcntNo(data.result.email);
                    setAcntPwd("");
                    setSub(data.result.sub);
                    setSubType("1");
                    var s = document.getElementById('sys_signin');
                    s.click();
                    var s = document.getElementById('createBtn');
                    s.click();
                }
                else {
                    alert(data.message);
                }

            })
            .catch(err => {
                console.log(err);
            })
    }

    //一般登入
    function LoginSubmit() {
        var dt =
        {
            AcntNo: AcntNo,
            AcntPwd: AcntPwd,
        }
        //console.log(dt);
        //一般登入
        API_SignIn(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(path);
                    //if (!checkAuthToken(data)) {
                    //    alert("token錯誤");
                    //    history.push("/");
                    //}
                    //var c = checkAuthToken(data);
                    //console.log(data.result);
                    checkAuthToken(data);
                    localStorage.setItem('UserData', JSON.stringify(data.result));
                    alert("登入成功");
                    var s = document.getElementById('sys_signin');
                    s.click();
                    setUserData(JSON.stringify(data.result));

                    localStorage.setItem('UB', JSON.stringify(data.result.ub));
                    //console.log(JSON.stringify(data.result.ub));
                    setUB(JSON.stringify(data.result.ub));
                    //history.push(path);
                }
                else {
                    alert(data.message);
                }

            })
            .catch(err => {
                console.log(err);
            })
        event.preventDefault();
    }

    //註冊
    function SignUpSubmit() {

        var go = true;

        //todo:alert瑕疵 鍵盤Enter可以正常執行 按滑鼠左鍵會失靈

        if (AcntPwd.length < 8 || AcntPwd.length > 12) {
            go = false;
            alert('長度輸入錯誤，請輸入正確的密碼限制字元。');
        }

        const rules = /^(?=.*\d)(?=.*[A-Za-z0-9]).{8,12}$/;
        if (!rules.test(AcntPwd)) {
            go = false;
            alert('請填入正確的密碼格式(請設定8-12位英數混合字元)。');
        }

        if (AcntPwd != AcntRePwd) {
            go = false;
            alert('二次密碼輸入不一致，請重新輸入。');
        }

        if (Cky == false) {
            go = false;
            alert('請勾選「同意隱私權政策和使用者條款」');
        }
        
        if (go) {
            const birStr = BirYear + '-' + BirMonth.padStart(2, "0") + '-01'; //ex: 1911-12-01
            console.log("birStr:" + birStr);

            var dt =
            {
                AcntNo: AcntNo,
                AcntPwd: AcntPwd,
                ckAcntPwd: AcntRePwd,
                AcntName: AcntName,
                SubLoginID: sub,
                SubLoginType: subType,
                UserPhone: UserPhone,
                BirDay: birStr,
                Verify: Verify
            }
            //console.log(dt);
            API_SignUp(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {
                        //console.log(data);
                        checkAuthToken(data);
                        alert("註冊成功，請登入");


                        $("#sys_signup").modal('hide');
                        $("#sys_signin").modal('show');

                    }
                    else if (data.code == "1016") {
                        alert("此電子信箱已註冊，請重新輸入");
                    } else {
                        alert(data.message);
                    }

                })
                .catch(err => {
                    console.log(err);
                })
            
        }

        event.preventDefault(); //停止事件
    }

    //登出
    function Logout() {
        localStorage.clear();
        setUserData(null);
        window.location = "/";
    }

    //忘記密碼
    function ForgetPWSubmit() {

        var go = true;

        if (ForgetAcntPwd.length < 8 || ForgetAcntPwd.length > 12) {
            go = false;
            alert('長度輸入錯誤，請輸入正確的密碼限制字元。');
        }

        const rules = /^(?=.*\d)(?=.*[A-Za-z0-9]).{8,12}$/;

        if (!rules.test(ForgetAcntPwd)) {
            go = false;
            alert('請填入正確的密碼格式(請設定8-12位英數混合字元)。');
        }


        if (ForgetAcntPwd != ForgetAcntRePwd) {
            go = false;
            alert('二次密碼輸入不一致，請重新輸入。');
        }

        if (go) {
            var dt =
            {
                AcntNo: ForgetAcntNo,
                NewPassword: ForgetAcntPwd,
                RePassword: ForgetAcntRePwd,
                Verify: ForgetVerify,
            }

            console.log(dt);

            API_POST_ForgetPwd(dt)
                .then((response) => response.data)
                .then((data) => {

                    if (data.code == "0000")
                    {
                        //console.log(data);
                        checkAuthToken(data);
                        alert("更改完成，請重新登入。");

                        $("#sys_forgetPW").modal('hide');
                        $("#sys_signin").modal('show');
                 
                    }
                    else
                    {
                        alert(data.message);
                    }

                })
                .catch(err => {
                    console.log(err);
                })

        }

        event.preventDefault(); //停止事件
    }

    //取得驗證碼 註冊-0 忘記密碼-2
    function VerifySubmit(type) {
     
        var dt =
        {
            email: (type == 2 ? ForgetAcntNo : AcntNo),
            CertType: type
        }
        console.log(dt);

        API_POST_Verify(dt)
            .then((response) => response.data)
            .then((data) => {
              
                checkAuthToken(data); //檢查是否逾時

                if (data.code == "1018") {
                    alert("已將驗證碼寄送至您的信箱。");
                }
                else if (data.code == "2003") {
                    alert("Email為空，請重新輸入。");
                }
                else if (data.code == "1019") {
                    alert("信箱格式有誤，請重新輸入。");
                }
                else if (data.code == "1017") {
                    alert("此信箱已註冊過。");
                }
                else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    //不知道做啥
    //document.onkeydown = function (event) {
    //    var target, code, tag;
    //    if (!event) {
    //        event = window.event;
    //        code = event.keyCode;
    //        if (code == 13) {
    //            submit();
    //        }
    //    } else {
    //        target = event.target; //針對遵循w3c標準的瀏覽器
    //        code = event.keyCode;
    //        if (code == 13) {
    //            submit();
    //        }
    //    }
    //};

    return (

        <header>
            <nav className="headerBar fixed-top">

                <div className="navbar navbar-expand-lg navbar-light" style={{ margin: 0  , padding: 0 }}  >

                    <a className="navbar-brand logo" href="/">
                        <img src="images/logo.png" style={{ "width": "6rem" }}  />
                        <span className="wTit" style={{ aontweight: 600 }}>&nbsp;會考寫作專業輔導</span>
                    </a>

                    <button className="my-2 navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#headerBarList"
                        aria-controls="headerBarList" aria-expanded="false">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="headerBarList">
 
                        <ul className="navbar-nav barList ml-auto">
                            <li className="nav-item p-2">
                                <a className="nav-link" href="../About">聊聊輕鬆寫作吧</a>
                            </li>
                            <li className="nav-item p-2">
                                <a className="nav-link" href="../Service">求批閱</a>
                            </li>
                            <li className="nav-item p-2">
                                <a className="nav-link" href="../Exam">會考衝刺專案</a>
                            </li>
                            <li className="nav-item p-2">
                                <a className="nav-link" href="../News">最新消息</a>
                            </li>

                            <li className="nav-item dropdown p-2">
                                <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                                    aria-expanded="false" id="Menu2">來點靈感</a>
                                <div className="dropdown-menu" aria-labelledby="Menu2">
                                    <a className="dropdown-item" href="../Ideal">測驗評分規準</a>
                                    <a className="dropdown-item" href="../Ideal?exam=history">歷屆會考試題</a>
                                    {/*<a className="dropdown-item" href="../Ideal?exam=practice">模擬考試題</a>*/}
                                </div>
                            </li>

                            {/*<li className="nav-item dropdown p-2">*/}
                            {/*    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"*/}
                            {/*        aria-expanded="false" id="Menu3">輕鬆TALK</a>*/}
                            {/*    <div className="dropdown-menu" aria-labelledby="Menu3">*/}
                            {/*        <a className="dropdown-item" href="#">一起聊聊吧</a>*/}
                            {/*        <a className="dropdown-item" href="#">學生ONLY</a>        */}
                            {/*        <a className="dropdown-item" href="#">家長ONLY</a>*/}
                            {/*    </div>*/}
                            {/*</li>*/}

                            {/*<li className="nav-item p-2"> */}
                            {/*    <a className="nav-link" href="#" onClick={e => islogin ? (localStorage.clear(), window.location = '/') : window.location = '/IdentityVerify'}>{loginStr}</a>*/}
                            {/*</li>*/}

                            {/*{*/}
                            {/*    UserData != null ?*/}
                            {/*        <li className="nav-item p-2">*/}
                            {/*            <a className="nav-link" href="/Member">會員中心</a>*/}
                            {/*        </li>*/}
                            {/*        :*/}
                            {/*        null*/}
                            {/*}*/}

                            {
                                UserData == null ?
                                    <li className="nav-item p-2">
                                        <a id="signinRemind" data-toggle="modal" className="nav-link" href="#sys_signin">註冊/登入</a>
                                    </li>
                                    :
                                    <li className="nav-item dropdown p-2">
                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                                            aria-expanded="false" id="memberMenu">會員中心</a>
                                        {/*<img src="images/member.svg" style={{ width: "2rem" }} />*/}
                                        <div className="dropdown-menu" aria-labelledby="memberMenu">
                                            <a className="dropdown-item" href="/Member"><img src="images/帳號設定.svg" style={{ width: "2rem" }} />帳號設定</a>
                                            <a className="dropdown-item" href="/Points"><img src="images/儲值查詢.svg" style={{ width: "2rem" }} />儲值與查詢</a>
                                            <a className="dropdown-item" href="/Orders"><img src="images/訂單查詢.svg" style={{ width: "2rem" }} />批閱結果/訂單查詢</a>
                                            {/*<a className="dropdown-item" href="#"><img src="images/邀請好友.svg" style={{ width: "2rem" }} />邀請朋友</a>*/}
                                            <a className="dropdown-item" href="#" onClick={Logout}><img src="images/登出.svg" style={{ width: "2rem" }} />登出</a>
                                        </div>
                                    </li>
                            }
                            
                         
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="modal fade" id="sys_signin" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth":"450px"}}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        {/*<div style={{*/}
                        {/*    backgroundColor: "#5daabe",color:"white" }} className="p-4" >*/}
                        {/*    <span>輕鬆幣儲值優惠中！<br/>即日起至1月底買1000送500</span>*/}
                        {/*</div>*/}
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "2vh"}}>
                                <div className="col-10">
                                    <fieldset>
                                        <legend style={{ "fontWeight": "600" }}>登入</legend>
                                        <form name="fm_login" onSubmit={LoginSubmit} className="sys_signin_Form" id="LogIn">
                                            <div className="form-group">
                                                <label htmlFor="login_id">電子信箱</label>
                                                <input type="text" className="form-control" id="SID" name="SID" value={AcntNo} onChange={(e) => (setAcntNo(e.target.value))} required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login_id">密碼</label>
                                                <input data-toggle="password" type="password" className="form-control" id="PWD"
                                                    name="PWD" maxLength="18" placeholder="" value={AcntPwd} onChange={(e) => (setAcntPwd(e.target.value))} required />
                                            </div>
                                            <div className="form-group">

                                                {/*<label htmlFor="login_id">驗證碼&emsp;<span style={{ color:"#4bacc6"}}>jo3sp</span></label>*/}
                                                {/*    <input name="NewCode" type="text" className="form-control" id="NewCode" maxLength="4"*/}
                                                {/*        size="12"/>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="form-group">*/}
                                                <label htmlFor="login_id">或使用以下選項登入</label>
                                                <GoogleLogin

                                                    onSuccess={credentialResponse => {
                                                        OAuthLogin(credentialResponse.credential);
                                                    }}
                                                    onError={() => {
                                                        console.log(credentialResponse);
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-4 mb-4 text-right">
                                                <a style={{
                                                    textDecoration: "underline", color: "red", cursor: "pointer"
                                                    /* onClick = "/showSignup()"*/
                                                }} onClick={showSignup} href="#sys_signup" data-toggle="modal" id="createBtn"
                                                >註冊新的帳號</a>

                                                <a style={{
                                                    textDecoration: "underline", color: "red", cursor: "pointer", paddingLeft: "20px" 
                                                }} onClick={showSignup} href="#sys_forgetPW" data-toggle="modal" id="ForgetBtn"
                                                >忘記密碼</a>
                                            </div>
                                            <div className="signInBtn">
                                                <button type="submit"  className="btn-ezw-primary btn-block">登入</button>
                                                    </div>
                                                </form>
                                            </fieldset>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            {/*註冊帳號*/}
            <div className="modal fade" id="sys_signup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth:"450px"}} >
                    <div className="modal-content" style={{ margin: "0 1rem"}} >
                        {/*<div className="p-4" style={{ backgroundColor: "#5daabe", color: "white" }} >*/}
                        {/*    <span>首次註冊帳號，送紅利點數</span>*/}
                        {/*</div>*/}
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "2vh"}} >
                                <div className="col-10">
                                    <fieldset>
                                        <legend style={{ fontWeight: "600" }} >註冊新帳號</legend>

                                        <form name="fm_login" onSubmit={SignUpSubmit} className="sys_signin_Form">
                                            <div className="form-group">
                                                <label htmlFor="login_id"><span className="text-danger">*</span>使用者名稱</label>
                                                <input type="text" className="form-control" id="login_id" name="login_id" id="UserName" value={AcntName} onChange={(e) => (setAcntName(e.target.value))}
                                                    autoComplete="off" required/>
                                            </div>

                                            <div className="form-group" style={{ marginBottom: "0px"}}>
                                                <label htmlFor="login_email"><span className="text-danger">*</span>電子信箱</label>
                                            </div>

                                            <div class="form-group input-group mb-3">
                                                <input type="text" className="form-control" id="login_email" name="login_email" value={AcntNo} onChange={(e) => (setAcntNo(e.target.value))}
                                                    autoComplete="off" />
                                                <div class="input-group-append">
                                                    <button type="Button" class="btn btn-outline-secondary" id="verify" className="btn-ezw-primary btn-block"
                                                        onClick={(e)=>VerifySubmit(0)} >取得驗證碼</button>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <label htmlFor="login_verify"><span className="text-danger">*</span>驗證碼</label>
                                                <input type="text" className="form-control" id="login_verify" name="login_verify" value={Verify} onChange={(e) => (setVerify(e.target.value)) }  autoComplete="off" required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="login_id">電話(選填)</label>
                                                <input type="text" className="form-control" id="UserPhone" name="UserPhone" value={UserPhone} onChange={(e) => (setUserPhone(e.target.value))}
                                                    autoComplete="off" />
                                            </div>
                                            <label htmlFor="BirYear"><span className="text-danger">*</span>出生年月</label>
                                            <div className="form-group row">
                                                <div className="col">
                                                    <select id="BirYear" name="BirYear" className="form-control" onChange={(e) => (setBirYear(e.target.value))} required>
                                                        <option value="">請選擇</option>
                                                        {BirYearList}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <select id="BirMonth" name="BirMonth" className="form-control" onChange={(e) => (setBirMonth(e.target.value))} required>
                                                        <option value="">請選擇</option>
                                                        {BirMonthList}
                                                    </select>
                                                </div>
                                                
                                                {/*<input type="text" className="form-control" id="BirDay" name="BirDay"/>*/}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login_pwd"><span className="text-danger">*</span>密碼(請設定8-12位英數混合字元)</label>
                                                <input data-toggle="password" type="password" className="form-control" id="login_pwd"
                                                    name="login_pwd" maxLength="18" placeholder="" autoComplete="off" value={AcntPwd} onChange={(e) => (setAcntPwd(e.target.value))}  required/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login_pwd"><span className="text-danger">*</span>確認密碼</label>
                                                <input data-toggle="password" type="password" className="form-control" id="login_pwd2"
                                                    name="login_pwd2" maxLength="18" placeholder="" autoComplete="off" value={AcntRePwd} onChange={(e) => (setAcntRePwd(e.target.value))} required />
                                            </div>
                                            <div className="signInBtn">
                                                <button type="submit" className="btn-ezw-primary btn-block">註冊</button>
                                            </div>
                                            <div className="mt-2" style={{ textAlign:"center"}} >
                                                <input type="checkbox"  name="ckY" id="ckY" onChange={(e) => (setCky(e.target.value))} />
                                                <label  htmlFor="ckY">註冊即同意 <a href="images/privacy.jpg" data-fancybox="p" style={{ textDecoration: "underline" }}>隱私權政策和使用者條款</a></label>
                                            </div>
                                        </form>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*忘記密碼*/}
            <div className="modal fade" id="sys_forgetPW" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "450px" }} >
                    <div className="modal-content" style={{ margin: "0 1rem" }} >
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "2vh" }} >
                                <div className="col-10">
                                    <fieldset>
                                        <legend style={{ fontWeight: "600" }} >忘記密碼</legend>
                                        <form name="fm_login" onSubmit={ForgetPWSubmit} className="sys_signin_Form">
                                            <div className="form-group" style={{ marginBottom: "0px" }}>
                                                <label htmlFor="login_email"><span className="text-danger">*</span>電子信箱</label>
                                            </div>

                                            <div class="form-group input-group mb-3">
                                                <input type="text" className="form-control" id="login_email" name="login_email" value={ForgetAcntNo} onChange={(e) => (setForgetAcntNo(e.target.value))}
                                                    autoComplete="off" />
                                                <div class="input-group-append">
                                                    <button type="Button" class="btn btn-outline-secondary" id="verify" className="btn-ezw-primary btn-block"
                                                        onClick={(e) => VerifySubmit(2)} >取得驗證碼</button>
                                                </div>
                                            </div>
                                           
                                            <div className="form-group">
                                                <label htmlFor="login_pwd"><span className="text-danger">*</span>密碼(請設定8-12位英數混合字元)</label>
                                                <input data-toggle="password" type="password" className="form-control" id="login_pwd"
                                                    name="login_pwd" maxLength="18" placeholder="" autoComplete="off" value={ForgetAcntPwd} onChange={(e) => (setForgetAcntPwd(e.target.value))} required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login_pwd"><span className="text-danger">*</span>確認密碼</label>
                                                <input data-toggle="password" type="password" className="form-control" id="login_pwd2"
                                                    name="login_pwd2" maxLength="18" placeholder="" autoComplete="off" value={ForgetAcntRePwd} onChange={(e) => (setForgetAcntRePwd(e.target.value))} required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login_verify"><span className="text-danger">*</span>驗證碼</label>
                                                <input type="text" className="form-control" id="login_verify" name="login_verify" value={ForgetVerify} onChange={(e) => (setForgetVerify(e.target.value)) } autoComplete="off" required />
                                            </div>

                                            <div className="signInBtn">
                                                <button type="submit" className="btn-ezw-primary btn-block">送出</button>
                                            </div>
                                        </form>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default Header;
