import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_POST_CreateWriting, API_GET_ImgByteInfo, API_GET_UserPayPoint } from '../global/constants';
import { checkAuthToken } from '../global/TokenUtil';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Online() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [imgByte, setImgByte] = useState(0);
    //可上傳檔案大小為0則為未正確連線伺服器
    const maxMultFile = 4;
    const warnMSG = {
        'overSize': `檔案大小過大: 上傳檔案不能超過 ${parseInt(imgByte / 1024 / 1024)} MB`,
        'overFileAMG': `超過檔案上傳數量上限: ${maxMultFile} 份`,
        'noTitle': `作文題目為必填`,
        'noFile2': `作文圖檔至少上傳一張`,
        'noUserPhone': `手機為必填`,
        'UserPhoneReg': `請輸入正確的手機號碼 (EX:0910123456)。`,
        'noReserveTime': `線上輔導預約必填`
    };
    const [uploadPlain, setUploadPlain] = useState('');
    const [openFileData, setOpenFileData] = useState({ Name: '', ImageBase64: '' });
    const [MultFileData, setMultFileData] = useState([]);
    const [title, setTitle] = useState('');
    
    const [UserPayPointList, setUserPayPointList] = useState([{}]);

    const [GpointList, setGpointList] = useState([{}]); //有使用紅利
    const [NGpointList, setNGpointList] = useState([{}]); //不使用紅利

    const [UserPhone, setUserPhone] = useState('');
    const [ReserveDate, setReserveDate] = useState('');
    const [ReserveTime, setReserveTime] = useState('');

    const [Period, setPeriod] = useState('');
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    const [timeList, setTimeList] = useState([
        { Tid: '1', Ttext: '08:30~08:55', Tdisabled: '0', TPeriod: '1' },
        { Tid: '2', Ttext: '09:00~09:25', Tdisabled: '0', TPeriod: '1' },
        { Tid: '3', Ttext: '09:30~09:55', Tdisabled: '0', TPeriod: '1' },
        { Tid: '4', Ttext: '10:00~10:25', Tdisabled: '0', TPeriod: '1' },
        { Tid: '5', Ttext: '10:30~10:55', Tdisabled: '0', TPeriod: '1' },
        { Tid: '6', Ttext: '11:00~11:25', Tdisabled: '0', TPeriod: '1' },
        { Tid: '7', Ttext: '13:30~13:55', Tdisabled: '0', TPeriod: '2' },
        { Tid: '8', Ttext: '14:00~14:25', Tdisabled: '0', TPeriod: '2' },
        { Tid: '9', Ttext: '14:30~14:55', Tdisabled: '0', TPeriod: '2' },
        { Tid: '10', Ttext: '15:00~15:25', Tdisabled: '0', TPeriod: '2' },
        { Tid: '11', Ttext: '15:30~15:55', Tdisabled: '0', TPeriod: '2' },
        { Tid: '12', Ttext: '16:00~16:25', Tdisabled: '0', TPeriod: '2' },
        { Tid: '13', Ttext: '16:30~16:55', Tdisabled: '0', TPeriod: '2' },
        { Tid: '14', Ttext: '17:00~17:25', Tdisabled: '0', TPeriod: '2' },
        { Tid: '15', Ttext: '18:30~18:55', Tdisabled: '0', TPeriod: '2' },
        { Tid: '16', Ttext: '19:00~19:25', Tdisabled: '0', TPeriod: '3' },
        { Tid: '17', Ttext: '19:30~19:55', Tdisabled: '0', TPeriod: '3' },
        { Tid: '18', Ttext: '20:00~20:25', Tdisabled: '0', TPeriod: '3' },
        { Tid: '19', Ttext: '20:30~20:55', Tdisabled: '0', TPeriod: '3' },
        { Tid: '20', Ttext: '21:00~21:25', Tdisabled: '0', TPeriod: '3' }
    ]);

    useEffect(() => {

        getImgByteInfo();

        getUserPayPoint();

        const sdate = new Date();
        sdate.setDate(sdate.getDate() + 4);
        setMinDate(dateToYMD(sdate));
        //console.log(minDate);

        const edate = new Date();
        edate.setDate(edate.getDate() + 30);
        setMaxDate(dateToYMD(edate));
        //console.log(maxDate);

        let urltak = queryParams.get('plain');
        if (urltak) {
            urltak = String(urltak).toLowerCase()
            if (urltak == "exam") {
                setUploadPlain('exam');
            }
        }
    }, []);

    function dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    function getImgByteInfo() {
        API_GET_ImgByteInfo().then((response) => response.data)
            .then((data) => {
                checkAuthToken(data); //檢查是否逾時
                console.log(data);
                if (data.code == "0000") {
                    //console.log("呼叫成功");

                    setImgByte(data.result[0].SysValue);
                } else {
                    setImgByte(5 * 1024 * 1024);
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getUserPayPoint() {
        API_GET_UserPayPoint({
            money: '700'
        }).then((response) => response.data)
            .then((data) => {
                //console.log('--------------------------');
                console.log(data.result);
                if (data.code == "0000") {
                    setUserPayPointList(data.result);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function openFileBtn1() {
        document.getElementById("File1").click();
    }
    function openFile1(event) {
        if (Object.keys(event.target.files).length) {
            let fileSize = parseInt(event.target.files[0].size);
            if (fileSize <= imgByte) {//檢查檔案大小
                //var input = event.target.fils[0]; //取得上傳檔案
                var reader = new FileReader(); //建立FileReader物件

                reader.readAsDataURL(event.target.files[0]); //以.readAsDataURL將上傳檔案轉換為base64字串

                reader.onload = function () { //FileReader取得上傳檔案後執行以下內容
                    var dataURL = reader.result; //設定變數dataURL為上傳圖檔的base64字串

                    //$('#output1').attr('src', dataURL).show(); //將img的src設定為dataURL並顯示
                    document.getElementById("output1").src = dataURL;
                    document.getElementById("output1").style.display = "inline-block";
                    document.getElementById("output1").previousElementSibling.style.display = "flex";
                    document.getElementById("output1").parentElement.style.height = '200px';
                    document.getElementById("output1").parentElement.style.margin = '16px 0';

                    setOpenFileData(prevState => ({ Name: event.target.files[0].name, ImageBase64: dataURL }));
                };
                return null;
            } else {
                alert(warnMSG['overSize']);
            }
        }
    }

    function deletFile1(event) {
        document.getElementById('File1').value = '';
        document.getElementById("output1").src = '';
        document.getElementById("output1").style.display = "block";
        document.getElementById("output1").previousElementSibling.style.display = "";
        document.getElementById("output1").parentElement.style.height = '0';
        document.getElementById("output1").parentElement.style.margin = '0';
        setOpenFileData(prevState => ({ Name: '', ImageBase64: '' }));
    }

    /*多圖片上傳儲存*/
    function openFile2(event) {
        //檢查檔案存在
        const files = event.target.files;
        const file = files[0];
        if (!file) return;
        //檢查檔案大小
        const fileSize = parseInt(file.size);
        if (fileSize > imgByte) {
            alert(warnMSG['overSize']);
            return;
        }

        //圖片存在處理生成渲染
        const reader = new FileReader();//建立FileReader物件
        reader.readAsDataURL(file);//以.readAsDataURL將上傳檔案轉換為base64字串
        reader.onload = function () {//FileReader取得上傳檔案後執行以下內容
            const dataURL = reader.result;//設定變數dataURL為上傳圖檔的base64字串

            const hostIndex = parseInt( event.target.id.replace(`mupFile`, ``));
            const outputId = `multoutput${hostIndex}`;
            const output = document.getElementById(outputId);
            //將img的src設定為dataURL並顯示
            output.src = dataURL;
            output.style.display = "inline-block";
            output.previousElementSibling.style.display = "flex";
            output.parentElement.style.height = '200px';
            output.parentElement.style.margin = '';

            //儲存檔名與base64值
            setMultFileData(prevState => ({
                ...prevState,
                [hostIndex]: { Name: file.name, ImageBase64: dataURL }
            }));
        };
    }
    //觸發點擊分配
    function openMultiFile(event) {
        //監聽input是否儲存檔案
        let indexI = 0;
        for (indexI = 0; indexI < maxMultFile; indexI++) {
            //優先空檔案選取input或選取資料不完整清空img
            const fileInput = document.getElementById(`mupFile${index}`);
            const fileOutput = document.getElementById(`multoutput${index}`);

            if (!document.getElementById(`mupFile${indexI}`).value || !document.getElementById(`multoutput${indexI}`).src) {
                //清空input與img
                document.getElementById(`multoutput${indexI}`).src = "";
                document.getElementById(`multoutput${indexI}`).style.display = "block";
                document.getElementById(`multoutput${indexI}`).previousElementSibling.style.display = "";
                document.getElementById(`multoutput${indexI}`).parentElement.style.height = '0';
                document.getElementById(`multoutput${indexI}`).parentElement.style.margin = '0';

                //清空儲存值
                setMultFileData(prevState => ({
                    ...prevState,
                    [indexI]: { Name: "", ImageBase64: "" }
                }));

                //主動觸發選取檔案
                document.getElementById(`mupFile${indexI}`).click();
                break;
            }
        }
        //無可觸發檔案
        if (indexI >= maxMultFile) {
            alert(warnMSG['overFileAMG']);
        }

    }
    //初始畫面生成元件
    const combeGen = (maxFile) => {
        //建立儲存空間
        useEffect(() => {
            for (let i = 0; i < maxFile; i++) {
                setMultFileData(prevState => ({
                    ...prevState,
                    [i]: { Name: "", ImageBase64: "" }
                }));
            }
        }, []);

        //生成多個圖片上傳空間
        return (
            <>
                {Array.from({ length: maxFile }, (_, i) => (
                    <div id={`mup${i}`} className="uploadPicContain" style={{ height: "0", margin: "0" }}>
                        <span className="signX btn-danger" id={`mupSpan${i}`} onClick={deletFile2}>
                            ✖
                        </span>
                        <img id={`multoutput${i}`} style={{ display: "none" }} />
                        <input
                            type="file"
                            className="d-none"
                            id={`mupFile${i}`}
                            onChange={openFile2}
                            accept="image/*"
                        />
                    </div>
                ))}
                <input
                    type="button"
                    className="btn-ezw-primary btn-sm"
                    onClick={openMultiFile}
                    value="選擇檔案"
                />
            </>
        );
    }

    //刪除預覽
    function deletFile2(event) {
        event.target.nextElementSibling.src = '';
        event.target.nextElementSibling.nextElementSibling.value = '';
        event.target.nextElementSibling.style.display = "block";
        event.target.style.display = "";
        event.target.parentElement.style.height = '0';
        event.target.parentElement.style.margin = '0';

        const indexI = parseInt(event.target.id.replace(`mupSpan`, ''));
        //清空儲存值
        setMultFileData(prevState => ({
            ...prevState,
            [indexI]: { Name: "", ImageBase64: "" }
        }));

    }
    /*多圖片func end*/

    function sendPageOnline() {
        //未輸入標題
        if (title.length <= 0) {
            return alert(warnMSG[`noTitle`]);
        }

        const AnsArr = [];

        var indexI = 0, emptyArr = true;
        for (indexI = 0; indexI < maxMultFile; indexI++) {
            if (MultFileData[indexI].Name != "" && MultFileData[indexI].ImageBase64 != "") {
                AnsArr.push({ Name: MultFileData[indexI].Name, ImageBase64: MultFileData[indexI].ImageBase64 });
                emptyArr = false;
            }
        }

        //至少上傳一張圖片
        if (emptyArr) {
            return alert(warnMSG[`noFile2`]);
        }

        const QunArr = [];

        if (openFileData.Name != "" && openFileData.ImageBase64 !="" ) {
            QunArr.push({ Name: openFileData.Name, ImageBase64: openFileData.ImageBase64 });
        }

        if (UserPhone == "") {
            return alert(warnMSG[`noUserPhone`]);
        }

        var p_reg = new RegExp("^09[0-9]{8}$", "ig");
        if (!p_reg.exec(UserPhone)) {
            return alert(warnMSG[`UserPhoneReg`]);
        }

        if (ReserveDate == "" || ReserveTime == "") {
            return alert(warnMSG[`noReserveTime`]);
        }

        //console.log("ReserveDate:" + ReserveDate);
        //console.log("ReserveTime:" + ReserveTime);

        var rdVar = ReserveDate + "T00:00:00";


        var OPayType = 'P';
        if (uploadPlain == "exam") {
            OPayType = '3';
        }

        //console.log(UserPayPointList[0].UseType);


        if (OPayType == 'P') {
            // *700輕鬆幣 或 630輕鬆幣+700紅利點數
            // 350輕鬆幣 或 315輕鬆幣+350紅利點數
            // 120輕鬆幣 或 108輕鬆幣+120紅利點數
            var ct = `<div>本次批閱申請需要 700 輕鬆幣</div>`
                + `<div>我要使用以下方式：<br><div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="mode" id="mode1" value="1" />
                    <label class="form-check-label" for="mode1" style={{ fontSize: "1rem" }}>${UserPayPointList[1].UseMoney}輕鬆幣</label>
                </div><br><div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="mode" id="mode2" value="2" />
                    <label class="form-check-label" for="mode2" style={{ fontSize: "1rem" }}>${UserPayPointList[0].UseMoney}輕鬆幣+${UserPayPointList[0].UseGMoney}紅利點數</label>
                </div></div>`;

            $.confirm({
                title: '',
                content: ct,
                theme: 'material',
                type: 'blue',
                columnClass: 'col-sm-6',
                animation: 'zoom',
                closeAnimation: 'scale',
                buttons: {
                    取消: { action: function () { } },
                    tryAgain: {
                        text: 'OK',
                        btnClass: 'btn-blue',
                        action: function () {
                            var mVal = $("input[name='mode']:checked").val();

                            var PPoint = 0;
                            var PGiftPoint = 0;
                            if (mVal == '1') {
                                PPoint = 700;
                                PGiftPoint = 0;
                            } else if (mVal == '2') {
                                PPoint = 630;
                                PGiftPoint = 700;
                            }

                            //alert($("input[name='mode']:checked").val());
                            if (mVal == undefined) {
                                alert('請選擇方式');
                                return false;
                            } else {
                                //alert(mVal);

                                var loading = document.getElementById('loading');
                                loading.style.display = "block";
                                API_POST_CreateWriting({
                                    OrderQun: title,
                                    OrderType: "1",
                                    QunATTList: QunArr,
                                    AnsATTList: AnsArr,
                                    OrderMoney: "700",
                                    PayPoint: PPoint,
                                    PayGiftPoint: PGiftPoint,
                                    OrderPayType: OPayType, //專案123
                                    ClassRDate: rdVar,
                                    ClassPhone: UserPhone,
                                    PDTimeID: ReserveTime
                                }).then((response) => response.data)
                                    .then((data) => {
                                        checkAuthToken(data); //檢查是否逾時
                                        console.log(data);

                                        if (data.code == "3029") {
                                            alert(data.message); //圖片名稱重複，請重新命名。
                                        } else if (data.code == "0000") {
                                            alert("儲存成功");
                                            window.location.reload();
                                        }
                                        loading.style.display = "none";

                                    })
                                    .catch(err => {
                                        console.log(err);
                                        if (err.response.status === 401 || err.response.data.code == "1001") {
                                            localStorage.clear();
                                            history.push('/');
                                        }
                                    })
                            }
                        }
                    },
                },
            });
        } else {
            var loading = document.getElementById('loading');
            loading.style.display = "block";
            API_POST_CreateWriting({
                OrderQun: title,
                OrderType: "1",
                QunATTList: QunArr,
                AnsATTList: AnsArr,
                OrderMoney: "700",
                PayPoint: 1,
                PayGiftPoint: 0,
                OrderPayType: OPayType, //專案123
                ClassRDate: ReserveDate,
                ClassPhone: UserPhone,
                PDTimeID: ReserveTime
            }).then((response) => response.data)
                .then((data) => {
                    checkAuthToken(data); //檢查是否逾時
                    console.log(data);

                    if (data.code == "3029") {
                        alert(data.message); //圖片名稱重複，請重新命名。
                 
                    } else if (data.code == "0000") {
                        alert("儲存成功");
                        window.location.reload();
                    }
                    loading.style.display = "none";

                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
        }
        

        
        //API_POST_CreateWriting({
        //    OrderQun: title,
        //    OrderType: "1",
        //    QunATTList: QunArr,
        //    AnsATTList: AnsArr
        //    //OrderMoney: "700",
        //    //PayPoint: "",
        //    //PayGiftPoint: "",
        //    //OrderPayType: "P"
        //}).then((response) => response.data)
        //    .then((data) => {
        //        checkAuthToken(data); //檢查是否逾時
        //        console.log(data);

        //        if (data.code == "3029") {
        //            alert(data.message); //圖片名稱重複，請重新命名。
        //        } else if (data.code == "0000") {
        //            alert("儲存成功");
        //            window.location.reload();
        //        }
                
        //    })
        //    .catch(err => {
        //        console.log(err);
        //        if (err.response.status === 401 || err.response.data.code == "1001") {
        //            localStorage.clear();
        //            history.push('/');
        //        }
        //    })
    }

    return (
        <>

            <div className="top"></div>
            <div id="main" className="" style={{ backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center;", backgroundSize: "cover", fontsize: "1.2rem", width: "100%", height: "100vh", position: "relative" }} >

                <div className="container">
                    <div className="row justify-content-center" style={{ paddingTop: "15vh" }} >
                        <div className="col-lg-10 col-md-12 col-sm-12" style={{ backgroundColor: "rgba(255, 255, 255, .6)", borderRadius: "1rem" }} >
                            <div className="container">
                                <div className="row justify-content-center" style={{ padding: "3vh" }} >
                                    <div className="col-12" style={{ overflowY: "scroll", maxHeight: "65vh", paddingBottom: "72px" }}>
                                        <fieldset>
                                            <legend style={{ fontWeight: "600", textAlign: "center", color: "rgb(234, 85, 19)" }}>
                                                {(uploadPlain == "exam"
                                                    ?
                                                    "登峰造極專案"
                                                    :
                                                    "一對一線上輔導")}
                                            </legend>
                                            <div style={{ textAlign: "center" }}>(含診斷式批閱+線上輔導)</div>
                                            <div className="mt-md-0 mt-2" style={{ fontSize: "medium", textAlign: "right" }} >
                                                {(uploadPlain == ""
                                                    ?
                                                    <>
                                                        <span className="text-danger">*</span>本次批閱申請需要<span className="text-danger">700</span>輕鬆幣
                                                        < br />
                                                        (或<span className="text-danger">630</span>輕鬆幣 + <span className="text-danger">700</span>紅利點數)
                                                    </>
                                                    :
                                                    <>
                                                        <span className="text-danger">*</span>本次批閱申請將扣取<span className="text-danger">乙次</span>批閱次數
                                                    </>
                                                ) }
                                            </div>
                                            <hr className="mt-4" />
                                            <div className="container">
                                                {/*<div className="row form-group">*/}
                                                {/*    <div className="col-4" style={{ fontWeight:"bold"}} >*/}
                                                {/*        上傳檔案須知*/}
                                                {/*    </div>*/}
                                                {/*    <div className="col-8">*/}
                                                {/*        <a href="">看教學影片</a>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="row form-group">
                                                    <div className="col-md-3 col-12" style={{ fontWeight: "bold" }} >
                                                        <span className="text-danger">*</span>作文題目(必填)
                                                    </div>
                                                    <div className="col-md-9 col-12">
                                                        <input type="text" className="form-control" id="title" name="title" autoComplete="off" required onChange={(e) => setTitle(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-md-3 col-12" style={{ fontWeight: "bold" }} >
                                                        題目圖檔(選填)
                                                    </div>
                                                    <div className="col-md-9 col-12">
                                                        <span className="d-block">
                                                            1.若為模擬考之題目頁，請將其拍照上傳。<br />
                                                            2.若為自訂題目，本欄可不必上傳。
                                                        </span>
                                                        <div className="uploadPicContain" style={{ height: "0", margin: "0" }}>
                                                            <span className="signX btn-danger" onClick={deletFile1}>✖</span>
                                                            <img id="output1" style={{ display: "none" }} />
                                                        </div>
                                                        <input type="file" className="d-none" id="File1" accept="image/*" onChange={openFile1} />
                                                        <hr className="border-0" style={{margin:"1px 0 0"}} ></hr>
                                                        <button type="button" className="btn-ezw-primary btn-sm" onClick={openFileBtn1} >選擇檔案</button>
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-md-3 col-12" style={{ fontWeight: "bold" }} >
                                                        <span className="text-danger">*</span>作文圖檔(必傳)
                                                    </div>
                                                    <div className="col-md-9 col-12">
                                                        <span className="d-block">
                                                            1.可利用拍照、掃描方式，電腦使用者可以手機掃二維條碼（每頁掃一次）後拍照上傳，為獲得最好的批閱品質，可參考<a href="images/service/pic.jpg" data-fancybox="l">技巧提點</a>。<br />
                                                            2.若圖片昏暗或模糊，系統將通知重新上傳。<br />
                                                            3.可上傳至多 {maxMultFile} 份檔案。
                                                        </span>
                                                        <span id="mup">
                                                            {combeGen(maxMultFile)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-md-3 col-12" style={{ fontWeight: "bold" }} >
                                                        <span className="text-danger">*</span>手機號碼(必填)<br /><span style={{ fontSize: ".7rem" }}>&emsp;(發送提醒簡訊使用)</span>
                                                    </div>
                                                    <div className="col-md-9 col-12">
                                                        <input type="text" className="form-control" id="UserPhone" name="UserPhone" autoComplete="off" required onChange={(e) => setUserPhone(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-md-3 col-12" style={{ fontWeight: "bold" }} >
                                                        <span className="text-danger">*</span>線上輔導預約(必填)
                                                    </div>
                                                    <div className="col-md-9 col-12">
                                                        <input type="date" className="form-control" name="ReserveDate" min={minDate} max={maxDate} onChange={(e) => setReserveDate(e.target.value)}/>
                                                        <div className="mt-4">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="period" id="period1" value="1" onChange={(e) => setPeriod(e.target.value)} />
                                                                <label className="form-check-label" htmlFor="period1" style={{ fontSize: "1rem" }}>早上</label>
                                                            </div>
                                                            <div className="form-check form-check-inline ml-4">
                                                                <input className="form-check-input" type="radio" name="period" id="period2" value="2" onChange={(e) => setPeriod(e.target.value)} />
                                                                <label className="form-check-label" htmlFor="period2" style={{ fontSize: "1rem" }}>下午</label>
                                                            </div>
                                                            <div className="form-check form-check-inline ml-4">
                                                                <input className="form-check-input" type="radio" name="period" id="period3" value="3" onChange={(e) => setPeriod(e.target.value)} />
                                                                <label className="form-check-label" htmlFor="period3" style={{ fontSize: "1rem" }}>晚上</label>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            {timeList.map((x, i) => (
                                                                (Period == 1 && x.TPeriod == '1') || (Period == 2 && x.TPeriod == '2') || (Period == 3 && x.TPeriod == '3')
                                                                ?
                                                                <div className="form-check form-check-inline mr-5">
                                                                        <input className="form-check-input" type="radio" name="ReserveTime" id={`time${x.Tid}`} value={x.Tid} onChange={(e) => setReserveTime(e.target.value)} disabled={ x.Tdisabled == '0' ? '' : 'disabled' }/>
                                                                    <label className="form-check-label" htmlFor={`time${x.Tid}`} style={{ fontSize: "1rem" }}>{x.Ttext}</label>
                                                                </div>
                                                                :
                                                                ''
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row form-group mt-5">
                                                    <div className="col-12 text-center">
                                                        <a id="backServiceBtn" className="btn-ezw-outline-primary btn-sm mx-3 text-decoration-none"  href="../Service">回上一頁</a>
                                                        <button id="applybtn" type="submit" className="btn-ezw-primary btn-sm mx-3" onClick={sendPageOnline}>確認送出</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="footer fixed-bottom">
                    <div className="row text-center justify-content-center">
                        <div className="col-12 ">

                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407040臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500 分機815　客服信箱：service@writingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Online;
