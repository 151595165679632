import axios from 'axios';
import { getAuthToken } from './TokenUtil';

export const getHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': token
    }
}

//API URL
//export const API_HOST = "https://localhost:7021/api";  //����
//export const API_HOST = "https://ez.writingbar.com.tw/api"; //������
export const API_HOST = "https://demo-ezwriting.summit-edu.com.tw/api"; //���վ�

//�@�뱡�p��
const baseRequest = axios.create({
    baseURL: API_HOST
});
//�U����
export const downloadRequest = axios.create({
    baseURL: API_HOST,
    responseType: 'blob'
});

//�]��
export const API_CHOST = "https://www.focas.fisc.com.tw/FOCAS_WEBPOS";

const baseCRequest = axios.create({
    baseURL: API_CHOST
});


//#region �@��api
export const apiLangSetting = data => baseRequest.get('/languageData');
export const apiAreaSetting = data => baseRequest.get('/areaData');
//#endregion

//#region �D�e��
//�D�e��-���o�v��
//export const API_GET_HomeData = data => baseRequest.get('/home/data', { headers: getHeaders(getAuthToken()) });
//#endregion

//#region �\��-0
//0-0 ���ͨϥΪ̱b��
//export const API_Account_Create = data => baseRequest.put('/account/createuser', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 
//0-3 ���m�K�X
//export const API_Account_ResetPWD = data => baseRequest.get('/authenticate/resetpwd', { params: data, headers: getHeaders(getAuthToken()) });
//0-4 �C�X�ϥΪ��v��-------------------- 
//0-5 �ϥΪ̵��U
export const API_SignUp = data => baseRequest.post('/Account/CreateAccount', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json',  'Access-Control-Allow-Origin': '*' }
});
//0-5 �ϥΪ̵n�J
export const API_SignIn = data => baseRequest.post('/Login/Login', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});
//0-5 �ϥΪ̵n�J
export const API_OAuth = data => baseRequest.post('/Login/OAuthLogin', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});

//���o���ҽX ���U�b��-0 �ѰO�K�X-2
export const API_POST_Verify = data => baseRequest.post('/Account/Verify', JSON.stringify(data), { params: data, headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } }); 

//�e�x �ѰO�K�X
export const API_POST_ForgetPwd = data => baseRequest.post('/Account/ForgetPwd', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//0-3 �ҵ{�C��
export const API_Course = data => baseRequest.get('/Front/CourseInfo', { params: data, headers: getHeaders(getAuthToken()) });
//0-3 �����C��
export const API_Carousel = data => baseRequest.get('/Front/FrontCarousel', { params: data, headers: getHeaders(getAuthToken()) });

//�˵��ǭ�
export const API_GET_AcntList = data => baseRequest.get('/User/AcntList', { params: data, headers: getHeaders(getAuthToken()) });

//�s��ӤH���
export const API_PUT_EditUser = data => baseRequest.put('/User/EditUser', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�x�Ȥ�צC��
export const API_GET_TopUpModeList = data => baseRequest.get('/TopUp/TopUpModeList', { params: data, headers: getHeaders(getAuthToken()) });

//�x�Ȼ��P��
export const API_POST_CreateRecBill = data => baseRequest.post('/TopUp/CreateRecBill', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���P���ʶR�����C��
export const API_GET_RecBillList = data => baseRequest.get('/TopUp/RecBillList', { params: data, headers: getHeaders(getAuthToken()) });

//�d�ݻ��P���x�ȻP�d��
export const API_GET_SumBill = data => baseRequest.get('/TopUp/SumBill', { params: data, headers: getHeaders(getAuthToken()) });


//�d�ݳ浧���P���ʶR����
export const API_GET_RecBillInfo = data => baseRequest.get('/TopUp/RecBillInfo', { params: data, headers: getHeaders(getAuthToken()) });

//�q��d�ߪ���
export const API_GET_CompositionList = data => baseRequest.get('/File/CompositionList', { params: data, headers: getHeaders(getAuthToken()) });

//�w���浧�g�@
export const API_GET_OrderQunInFo = data => baseRequest.get('/File/OrderQunInFo', { params: data, headers: getHeaders(getAuthToken()) });

export const API_GET_OrderAnsInFo = data => baseRequest.get('/File/OrderAnsInFo', { params: data, headers: getHeaders(getAuthToken()) });


//�]��
export const API_POST_OrderInquery = data => baseCRequest.post('/online/', JSON.stringify(data), {params:data, headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } });

//�W�Ǥ@�g�g�@
export const API_POST_CreateWriting = data => baseRequest.post('/File/CreateWriting', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���o�W���ɮפj�p
export const API_GET_ImgByteInfo = data => baseRequest.get('/File/ImgByteInfo', { params: data, headers: getHeaders(getAuthToken()) });

//�d�ݻ��P���x�ȻP�d��(�M�צ���)
export const API_GET_SumProjectBill = data => baseRequest.get('/TopUp/SumProjectBill', { params: data, headers: getHeaders(getAuthToken()) });

//���I���
export const API_GET_UserPayPoint = data => baseRequest.get('/TopUp/UserPayPoint', { params: data, headers: getHeaders(getAuthToken()) });

//�w����粒���^�Ӫ��ɮ�
export const API_GET_File_OrderImpInFo = data => baseRequest.get('/File/OrderImpInFo', { params: data, headers: getHeaders(getAuthToken()) });