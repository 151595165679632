import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function About() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <div className="top"></div>
          <div id="main" className=""
              style={{ backgroundImage: "url(images/about/background.jpg)", backgroundPosition: "center center", backgroundSize: "cover", fontSize: "1.2rem", width: " 100%", height: "100vh", position: "relative", paddingTop: "100px", overflowX: "hidden" }} >

              <div className="row aboutTxt justify-content-center mb-5">
                  <div className="col-xl-8 col-9">
                      <p>「輕鬆寫作吧」是一個由退休校長、老師及多位現在仍活躍於教育一線的文教工作者所組成的團隊。</p>

                      <p>經年累月在教育第一線，長期接觸眾多學子所寫的作文，我們發現寫作上的教測評輔四個環節中，「評閱」部分仍是一片荒漠，可說是教育中的「偏鄉」亦不為過。</p>

                      <p>大環境普遍缺少優質評閱，評閱過程不透明……等，長期惡性循環下，學子逐漸失去寫作動力，若不除去學生們心裡的疑問──「誰來改我的作文」、「誰能好好的給我指正？」只能徘徊在寫作的門口，卻始終找不到可進入的鑰匙。</p>

                      <p>2022年「輕鬆寫作吧」寫作輔導團隊正式成立，我們具備制定國內模擬考寫作評分標準的豐富經驗，成員們亦為全國學測模擬考閱卷委員，我們比誰都知道如何獲取寫作高分，在九萬多個教育現場期間、每年批閱高中作文超過20萬篇，堅強實力無庸置疑。</p>

                      <p>寫作真的一點都不難，可以很輕鬆。「輕鬆寫作吧」將自信帶領學子們掌握寫作的得分技巧，輕鬆地獲取高分。</p>
                  </div>
              </div>

              {/*<div>*/}
                  <div className="row featureTxt justify-content-center pt-5 pb-5">
                      <div className="col-xl-7 col-8">
                          <h1 className="text-center" >『輕鬆寫作吧』會考寫作輔導特色</h1>
                          <ul>
                              <li>透明化批閱：一般常見批閱只有短評和得分，無法得知精確的好壞與建議，而我們提供的診斷式批閱項目超越詳批，報表更顯示各項得分與建議。</li>
                              <li>提供修正方向：除了評語更給予建議，使學生更了解寫作方向。</li>
                              <li>可升級1對1輔導：預約時間與批閱教師線上互動、提問，等於貼身作文家教老師，這是補習班也無法提供的。</li>
                              <li>互動影音隨時看：線上輔導後產生動態影音，學生無限制觀看，可加深補強除弱印象，改善寫作缺失。</li>
                          </ul>
                      </div>
                  </div>
              {/*</div>*/}

              {/*<div>*/}
                  <div className="programDiv pt-5 pb-5 px-5">
                      <h1 className="text-center" style={{ color: "#876347" }}>輔導方案介紹</h1>

                      <table className="table table-bordered table-striped text-center align-items-center">
                          <thead className="thead-dark">
                              <tr>
                                  <th scope="col">內容＼方案</th>
                                  <th scope="col">一般批閱</th>
                                  <th scope="col">診斷式批閱</th>
                                  <th scope="col">一對一線上輔導</th>
                              </tr>
                          </thead>
                          <tbody className="table-light">
                              <tr>
                                  <th scope="row">批閱類型</th>
                                  <td colspan="3">國中教育會考寫作</td>
                              </tr>
                              <tr>
                                  <th scope="row">分數</th>
                                  <td>總分</td>
                                  <td>分項式給分+總分</td>
                                  <td>分項式給分+總分</td>
                              </tr>
                              <tr>
                                  <th scope="row" className="align-middle">批閱深度</th>
                                  <td className="align-middle">內容總評</td>
                                  <td>錯別字圈改<br />內容總評、眉批標記<br />眉批註解、學習建議</td>
                                  <td>錯別字圈改<br />內容總評、眉批標記<br />眉批註解、學習建議</td>
                              </tr>
                              <tr>
                                  <th scope="row" rowspan="2" className="align-middle">專人輔導</th>
                                  <td rowspan="2" className="align-middle">無</td>
                                  <td rowspan="2" className="align-middle">無</td>
                                  <td style={{ color: "#ea5513" }}>線上25分鐘</td>
                              </tr>
                              <tr>
                                  <td style={{ color: "#ea5513" }}>輔導教學影音下載</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              {/*</div>*/}


              {/*<div>*/}
                  <div className="feeDiv pt-5 pb-5 px-5">
                      <h1 className="text-center" style={{ color: "#876347" }}  >收費方式</h1>
                      <table className="table table-bordered table-striped text-center align-items-center">
                          <thead className="thead-dark">
                              <tr>
                                  <th scope="col">說明＼方案</th>
                                  <th scope="col">一般批閱</th>
                                  <th scope="col">診斷式批閱</th>
                                  <th scope="col">一對一線上輔導</th>
                              </tr>
                          </thead>
                          <tbody className="table-light">
                              <tr>
                                  {/*<th scope="row">普通<br />(訂單成立後3-4天)</th>*/}
                                  <th scope="row">輕鬆幣</th>
                                  <td><span style={{ color: "#ea5513" }} >120</span></td>
                                  <td><span style={{ color: "#ea5513" }} >350</span></td>
                                  <td><span style={{ color: "#ea5513" }} >700</span></td>
                              </tr>
                              <tr style={{ textAlign: "left" }} >
                                  <td colspan="4" style={{ padding: "1rem 2rem 0 2rem" }}>
                                      {/*1.會員儲值享有點數優惠活動。*/}
                                      {/*2.申請方案時可選擇<span style={{ color: "#ea5513" }} >直接付款</span>或<span style={{ color: "#ea5513" }}>扣點數</span>。*/}
                                      {/*3.批閱後若須升級一對一輔導，可於會員訂單處點選『升級』。*/}
                                      <ol>
                                          <li>會員儲值輕鬆幣，享有贈送紅利點數好康。</li>
                                          <li>會員使用服務時可用點數部分折抵輕鬆幣。</li>
                                      </ol>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-10">
                          <div className="serviceDiv pt-5 pb-5">
                              <h1 className="text-center" style={{ color: "#876347" }} >寫作輔導服務流程</h1>
                              <h5 className="text-center" style={{ color: "#6C6C6C", marginTop: "30px", marginBottom: "30px" }} ><u>一般批閱、診斷式批閱</u></h5>
                              <div className="text-center">
                                  <img src="images\about\process01.png" style={{ width: "100%", maxWidth: "500px" }} />
                              </div>

                              <h5 className="text-center" style={{ color: "#6C6C6C", marginTop: "60px", marginBottom: "30px" }} ><u>一對一線上輔導</u></h5>
                              <div className="text-center">
                                  <img src="images\about\process02.png" style={{ width: "100%", maxWidth: "700px" }} />
                              </div>
                          </div>
                      </div>
                  </div>
              {/*</div>*/}

              <div className="row justify-content-center qaTxt py-5">
                  <div className="col-xl-8 col-10">
                      <h1 className="text-center" style={{ color: "#876347" }}>常見問題Q&A</h1>

                      <div class="accordion" id="accordion">
                          <div class="card">
                              <div class="card-header" id="headingOne">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left qTitle" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                          診斷式批閱與一般批閱有甚麼不同？
                                      </button>
                                  </h2>
                              </div>

                              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                  <div class="card-body">
                                      除了提供一般作文批閱，我們首創診斷式批閱，逐字詞、段落批註，除了評語更有學習建議，<br />
                                      並針對題旨發揮、段落、材料掌握、用字遣詞等分別給予評分，是市面上少見的服務。
                                  </div>
                              </div>
                          </div>
                          <div class="card">
                              <div class="card-header" id="headingTwo">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left collapsed qTitle" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                          為何需要一對一輔導？
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                  <div class="card-body">
                                      藉著和老師的一對一互動，真正改進作文盲點，就像請了作文貼身家教一樣。
                                  </div>
                              </div>
                          </div>
                          <div class="card">
                              <div class="card-header" id="headingThree">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left collapsed qTitle" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                          一對一輔導要如何預約？要等很久嗎?
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                  <div class="card-body">
                                      填寫申請表後，可選擇輔導日期與時段，最快可於受理48小時後進行。
                                  </div>
                              </div>
                          </div>
                          <div class="card">
                              <div class="card-header" id="headingFour">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left collapsed qTitle" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                          一對一專人輔導是怎樣的服務?
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                  <div class="card-body">
                                      首先同學會先拿到第一次的批閱報表，並於預約好的時間上線，由專業輔導老師再做講解，<br />
                                      同學藉由當場提問，更了解自己的實力。還有互動影片供下載後隨時觀看。
                                  </div>
                              </div>
                          </div>
                          <div class="card">
                              <div class="card-header" id="headingFive">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left collapsed qTitle" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                          申請作文批閱需附上哪些東西呢？
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                  <div class="card-body">
                                      1. 作文題目<br />
                                      2. 寫好的作文檔案<br />
                                      作文可先掃描成電子檔，或利用手機App拍照，一次一頁，並將照片轉正，在申請時上傳
                                  </div>
                              </div>
                          </div>
                          <div class="card">
                              <div class="card-header" id="headingSix">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left collapsed qTitle" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                          我沒有掃描機，要怎麼將作文轉換成檔案？
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                  <div class="card-body">
                                      請下載手機app：例如Microsoft Lens(免費)，並在光源明亮的地方進行拍照，或拿至便利商店掃描(建議)，
                                  </div>
                              </div>
                          </div>
                          <div class="card">
                              <div class="card-header" id="headingSeven">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left collapsed qTitle" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                          我自己練習的作文也可以請老師改嗎？
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                  <div class="card-body">
                                      非常歡迎！也建議同學上傳模擬考時寫好的作文，一樣可達到輔導效果。
                                  </div>
                              </div>
                          </div>
                          <div class="card">
                              <div class="card-header" id="headingEight">
                                  <h2 class="mb-0">
                                      <button class="btn btn-link btn-block text-left collapsed qTitle" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                          目前只有國語文寫作批閱嗎？
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                  <div class="card-body">
                                      目前上線的服務為國語文寫作批閱服務，英文作文批閱目前正努力研發中喔!
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
              <div className="footer">
                  <div className="row text-center justify-content-center">
                      <div className="col-12 ">

                          <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407040臺中市西屯區長安路一段83號5樓<br />
                              電話：(04)2315-2500 分機815　客服信箱：service@writingbar.com.tw</p>
                      </div>
                  </div>
              </div>
          </div>
     </>
  );
}
 
export default About;
