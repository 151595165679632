import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_TopUpModeList, API_POST_CreateRecBill,
    API_GET_RecBillList, API_GET_RecBillInfo, API_POST_OrderInquery,
    API_GET_SumBill, API_GET_SumProjectBill, API_HOST
} from '../global/constants';
import { checkAuthToken } from '../global/TokenUtil';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Points() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [UserData, setUserData] = useState({});
    const [acntName, setAcntName] = useState();
    const [ToupID, setToupID] = useState('');
    const [Pay, setPay] = useState('');
    const [pointArray, setPointArray] = useState([{}]);

    const [ToupType, setToupType] = useState();

    const [modeTit, setModeTit] = useState();
    const [amount, setAmount] = useState();
    const [pType, setPType] = useState();

    const [billBankNo, setBillBankNo] = useState();
    const [billingDate, setBillingDate] = useState();
    const [createDate, setCreateDate] = useState();
    const [qrcode, setQrcode] = useState();
    const [billMoney, setBillMoney] = useState();

    const [recordArray, setRecordArray] = useState([{}]);
    const [recBillInfoArray, setRecBillInfoArray] = useState([{}]);

    const [infoCreateDate, setInfoCreateDate] = useState();
    const [infoBillType, setInfoBillType] = useState();
    const [infoBillMoney, setInfoBillMoney] = useState();
    const [infoBillBankNo, setInfoBillBankNo] = useState();
    const [infoBillingDate, setInfoBillingDate] = useState();
    const [infoQrcode, setInfoQrcode] = useState();

    const [infoBillState, setInfoBillState] = useState();
    const [infoPayType, setInfoPayType] = useState();
    const [infoPayDate, setInfoPayDate] = useState();
    const [infoPayMoney, setInfoPayMoney] = useState();
    const [infoGiftPointsEndDate, setInfoGiftPointsEndDate] = useState();

    //const [Bill, setBill] = useState({ SumPoint: 0, BuyCount: 0, SumGiftPoint: 0 }); //SumPoint:輕鬆幣, BuyCount:幾筆購買紀錄, SumGiftPoint:紅利點數
    const [Point, setPoint] = useState('');
    const [PointEndDate, setPointEndDate] = useState(''); //到期日期
    const [RecBill, setRecBill] = useState({ SumOrder: 0 }); //幾筆購買紀錄
    const [UserPointList, setUserPointList] = useState([{}]); //輕鬆幣P, 紅利點數G, 會考衝刺專案123

    const [UserPointPList, setUserPointPList] = useState({ CodeText: '', LastDate: '', LastPoint: 0, PointType: 'P', SysValue: '', UserID: 0, diffDay: 0 }); //輕鬆幣P
    const [UserPointGList, setUserPointGList] = useState({ CodeText: '', LastDate: '', LastPoint: 0, PointType: 'P', SysValue: '', UserID: 0, diffDay: 0 }); //輕鬆幣G

    //取得網址是否促銷方案
    const [takPlain, setTakPlain] = useState((-1));

    useEffect(() => {
        if (localStorage.getItem("UserData") != null) {
            setUserData(JSON.parse(localStorage.getItem("UserData")));
            /*console.log(JSON.parse(localStorage.getItem("UserData")));*/
            getPoints();

            let params = new URLSearchParams(window.location.search);
            let urltak = params.get('plain');
            if (urltak) {

                getData(urltak);
                setTakPlain(parseInt(urltak));
                showStoredValue();
                setToupID(urltak);
            } else {
                getData();
            }
        }
        else {
            window.location = "/";
        }
    }, []);

    function getPoints() {
        API_GET_SumBill().then((response) => response.data)
            .then((data) => {
                console.log(data.result);
                //console.log(data.result.userPoint);
                //setBill(data.result);

                //console.log(data.result.expiring.length);
                //console.log(data.result.recBill);

                

                var exDate = '';
                var exPoint = 0;
                if (data.result.expiring.length > 0) {
                    exDate = data.result.expiring[0].PointEndDate.split('T')[0];

                    exPoint = data.result.expiring[0].Point;
                    //console.log(data.result.expiring[0].Point);
                }
                //console.log(exDate);


                for (var i = 0; i < data.result.userPoint.length; i++) {
                    if (data.result.userPoint[i].PointType == 'P') {
                        setUserPointPList(data.result.userPoint[i]);
                    } else if (data.result.userPoint[i].PointType == 'G') {
                        setUserPointGList(data.result.userPoint[i]);
                    }
                }

                setPoint(exPoint);
                setPointEndDate(exDate);
                setRecBill(data.result.recBill[0]);
                setUserPointList(data.result.userPoint);
                
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getData(getUrl) {
        if (!getUrl) { getUrl = null }
        API_GET_TopUpModeList({
            ToupID: getUrl
        }).then((response) => response.data)
            .then((data) => {

                //console.log((checkAuthToken(data)));
                if (checkAuthToken(data)) {
                    alert("逾時請重新登入");
                    window.location = "/";
                }
                console.log(data);
                setPointArray(data.result);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function showRecord() {
        //$("#pointsDiv").hide();

        //$("#recordDiv").show();

        document.getElementById("pointsDiv").style.display = "none";
        document.getElementById("recordDiv").style.display = "block";

        API_GET_RecBillList().then((response) => response.data)
            .then((data) => {
                //console.log((!checkAuthToken(data)));
                if (!checkAuthToken(data)) {
                    alert("逾時請重新登入");
                    window.location = "/";
                }
                console.log(data);
                setRecordArray(data.result);

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function confirmMode() {
        //console.log("ToupID:" + ToupID);
        //console.log("Pay:" + Pay);


        if (ToupID == '') {
            return alert("請選擇儲值方案");

        } else if (Pay == '') {
            return alert("請選擇付款方式");

        }

        var abc = document.getElementById("Pg" + ToupID);
        //console.log(abc.getAttribute("data-touptype"));

        setToupType(abc.getAttribute("data-touptype"));


        setModeTit(document.getElementById("modeTit" + ToupID).innerHTML);
        setAmount(document.getElementById("amount" + ToupID).innerHTML);
        setPType(document.getElementById("pType" + Pay).innerHTML);

        document.getElementById("confirmDiv").style.display = "block";
        document.getElementById("TopUpModeDiv").style.display = "none";

    }

    function backTopUpMode() {
        document.getElementById("confirmDiv").style.display = "none";
        document.getElementById("TopUpModeDiv").style.display = "block";
    }

    function sendPoints() {
        console.log("ToupID:" + ToupID);
        console.log("Pay:" + Pay);

        console.log("ToupType:" + ToupType);
        var loading = document.getElementById('loading'); //載入圈圈
        loading.style.display = "block";
        API_POST_CreateRecBill({
            ToupID: ToupID,
            BillType: Pay,
            ToupType: ToupType
        }).then((response) => response.data)
            .then((data) => {
                checkAuthToken(data); //檢查是否逾時
                //console.log(data);
                //alert("儲存成功");

                if (Pay == 'A') {
                    setBillBankNo(data.result.billBankNo);
                    setBillingDate(data.result.billingDate_Str);
                    setCreateDate(data.result.createDate_Str);

                    document.getElementById("confirmDiv").style.display = "none";
                    document.getElementById("modeDetailDiv").style.display = "block";
                } else if (Pay == 'P') {
                    setBillingDate(data.result.billingDate_Str);
                    setCreateDate(data.result.createDate_Str);
                    setQrcode(data.result.qrcode);

                    document.getElementById("confirmDiv").style.display = "none";
                    document.getElementById("payDetailDiv").style.display = "block";
                } else if (Pay == 'C') {
                    //setBillMoney(data.result.billMoney);
                    //getOrderInquery();

                    setBillBankNo(data.result.billBankNo);

                    console.log(data.result.billMoney);
                    console.log(data.result.billBankNo);

                    var form = document.getElementById("creditform");
                    var purchAmt = document.getElementById("purchAmt");
                    purchAmt.value = data.result.billMoney;
                    //purchAmt.value = 1;
                    var lidm = document.getElementById("lidm");
                    lidm.value = data.result.billBankNo;
                    //lidm.value = "3888883052998002";
                    form.submit();
                }
                loading.style.display = "none"; //關閉圈圈


            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //function getOrderInquery() {
    //    API_POST_OrderInquery({
    //        MerchantID: '004242290279001',
    //        TerminalID: '90010001',
    //        merID: '24229027',
    //        purchAmt: billMoney,
    //        lidm: billBankNo
    //        //ResURL: ''
    //    }).then((response) => response.data)
    //        .then((data) => {
    //            //console.log((!checkAuthToken(data)));
    //            //if (!checkAuthToken(data)) {
    //            //    alert("逾時請重新登入");
    //            //    window.location = "/";
    //            //}


    //        })
    //        .catch(err => {
    //            console.log(err);
    //            //if (err.response.status === 401 || err.response.data.code == "1001") {
    //            //    localStorage.clear();
    //            //    history.push('/');
    //            //}
    //        })

    //}

    function goRecord() {
        document.getElementById("payDetailDiv").style.display = "none";
        document.getElementById("modeDetailDiv").style.display = "none";
        document.getElementById("recordDiv").style.display = "block";

        document.getElementById("billDetailDiv").style.display = "none";

        showRecord();
    }

    function getRecBillInfo(billID) {
        console.log(billID);

        document.getElementById("recordDiv").style.display = "none";
        document.getElementById("billDetailDiv").style.display = "block";

        API_GET_RecBillInfo({
            BillID: billID
        }).then((response) => response.data)
            .then((data) => {
                //console.log((!checkAuthToken(data)));
                if (!checkAuthToken(data)) {
                    alert("逾時請重新登入");
                    window.location = "/";
                }
                console.log(data);
                setRecBillInfoArray(data.result);

                setInfoCreateDate(data.result.CreateDate_Str);
                setInfoBillType(data.result.BillType);
                setInfoBillMoney(data.result.BillMoney);
                setInfoBillBankNo(data.result.BillBankNo);
                setInfoBillingDate(data.result.BillingDate_Str);

                setInfoBillState(data.result.BillState == '0' ? '待付款' : (data.result.BillState == '1' ? '已付款' : (data.result.BillState == '4' ? '已逾期' : '')));
                setInfoPayType(data.result.PayType);
                setInfoPayDate(data.result.PayDate_Str);
                setInfoPayMoney(data.result.PayMoney);
                setInfoGiftPointsEndDate(data.result.GiftPointsEndDate_Str);

                setInfoQrcode(data.result.Qrcode);

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    return (
        <>
            <div id="main" className="" style={{ backgroundColor: "#b8dce8", fontSize: "1.2rem", width: "100%", height: "100vh" }} >
                <div className="container" id="pointsDiv">
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>

                            <h2 style={{ textAlign: "center" }}><img src="images/儲值查詢.svg" style={{ width: "3.5rem" }} />儲值與查詢</h2>
                            <hr />
                            輕鬆幣：{UserPointPList.LastPoint} &emsp;
                            <button type="button" id="storedValueBtn" className="btn-ezw-primary btn-sm" onClick={showStoredValue}>儲值</button>
                            <br />
                            紅利點數：{UserPointGList.LastPoint}&emsp;
                            
                            {Point != 0
                                ?
                                <div>您有 {Point} 紅利點數將於 {PointEndDate} 到期</div>
                                :
                                ''
                                }
                            
                            <div>
                                購買紀錄：您有  <span style={{ color: "red" }} >{RecBill.SumOrder}</span>  筆購買紀錄
                                &emsp;<button type="button" id="recordBtn" className="btn-ezw-primary btn-sm" onClick={showRecord}>查詢</button>
                            </div>
                            {
                                UserPointList.map((x, i) => (
                                    (x.PointType == '1' || x.PointType == '2' || x.PointType == '3')
                                        ?
                                        <>
                                            <hr />
                                            會考衝刺專案「{x.CodeText}」剩餘次數：{x.LastPoint}
                                        </>
                                        :
                                        ''
                                ))
                            }

                            <hr />
                            <div><button type="button" className="btn btn-link" onClick={showEzMsg} >輕鬆幣</button>：用來支付作文輔導服務的方式，可儲值。</div>
                            <div><button type="button" className="btn btn-link" onClick={showPointMsg} >紅利點數</button>：可用於本網站付費服務時部分折抵，折抵金額以服務頁面所示為主。</div>

                        </div>
                    </div>
                </div>

                <div className="container" id="TopUpModeDiv" style={{ display: "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <h2 style={{ textAlign: "center" }}>

                                {
                                    (
                                        (takPlain == 6 || takPlain == 7 || takPlain == 8)
                                            ?
                                            "會考衝刺專案"
                                            :
                                            <div>
                                                <img src="images/儲值查詢.svg" style={{ width: "3.5rem" }} />
                                                儲值輕鬆幣
                                            </div>
                                    )
                                }

                            </h2>
                            <div className="font-weight-bold">儲值方案：</div>
                            <table className="table">
                                <tbody>
                                    {pointArray.map((x, i) => {
                                        if (x.ToupID == 6 || x.ToupID == 7 || x.ToupID == 8) {
                                            if (x.ToupID == takPlain) {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <input type="radio" key={i} id={`Pg${x.ToupID}`} name="ToupID" value={x.ToupID} onChange={(e) => setToupID(e.target.value)} data-id={i} data-touptype={x.ToupType} checked />
                                                            <label id={`modeTit${x.ToupID}`} htmlFor={`Pg${x.ToupID}`} className="pl-1">{x.ToupCaption}</label></td>
                                                        <td><span id={`amount${x.ToupID}`}>售價NT${x.TopupMoney}</span></td>
                                                    </tr>
                                                );
                                            }
                                        } else {
                                            return (
                                                <tr>
                                                    <td>
                                                        <input type="radio" key={i} id={`Pg${x.ToupID}`} name="ToupID" value={x.ToupID} onChange={(e) => setToupID(e.target.value)} data-id={i} data-touptype={x.ToupType} />
                                                        <label id={`modeTit${x.ToupID}`} htmlFor={`Pg${x.ToupID}`} className="pl-1">輕鬆幣{x.ToupPoints}個+紅利點數{x.GiftPoints}點</label></td>
                                                    <td><span id={`amount${x.ToupID}`}>售價NT${x.TopupMoney}</span></td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                </tbody>
                            </table>
                            {((takPlain == 6 || takPlain == 7 || takPlain == 8) ? "" : <div className="text-center text-danger mb-4">（紅利點數有效期限為自購買日起一年）</div>)}

                            <div className="font-weight-bold">付款方式：</div>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="Pay" id="pay1" value="C" onChange={(e) => setPay(e.target.value)} />
                                    <label className="form-check-label" htmlFor="pay1" id="pTypeC" style={{ fontSize: "1rem" }}>信用卡</label>
                                </div>
                                <div className="form-check form-check-inline ml-4">
                                    <input className="form-check-input" type="radio" name="Pay" id="pay2" value="A" onChange={(e) => setPay(e.target.value)} />
                                    <label className="form-check-label" htmlFor="pay2" id="pTypeA" style={{ fontSize: "1rem" }}>ATM轉帳</label>
                                </div>
                                <div className="form-check form-check-inline ml-4">
                                    <input className="form-check-input" type="radio" name="Pay" id="pay3" value="P" onChange={(e) => setPay(e.target.value)} />
                                    <label className="form-check-label" htmlFor="pay3" id="pTypeP" style={{ fontSize: "1rem" }}>台灣Pay</label>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                {/*<button type="button" id="backPointsBtn" className="btn-ezw-outline-primary  btn-sm" onClick={backPoints}>回上一頁</button>*/}
                                <a id="backPointsBtn" className="btn-ezw-outline-primary btn-sm" href="../Points">回上一頁</a>
                                <button type="button" id="nextBtn" className="btn-ezw-primary  btn-sm ml-3" onClick={confirmMode}>下一步</button>
                                {/*<a data-fancybox="" data-type="iframe" data-src="/Atm" href="javascript:;" class="btn btn-sm ml-3" style={{ backgroundColor: "#5daabe", borderColor: "#5daabe", color: "white" }} onClick={confirmMode} >下一步</a>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" id="recordDiv" style={{ display: "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <h2 style={{ textAlign: "center" }}>購買紀錄</h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>日期/時間</th>
                                        <th>付款方式</th>
                                        <th>金額</th>
                                        <th>付款結果</th>
                                        <th>電子發票</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordArray.map((x, i) => (
                                        <tr>
                                            <td>{x.CreateDate_Str}</td>
                                            <td>{x.BillType == 'C' ? '信用卡' : (x.BillType == 'A' ? 'ATM轉帳' : (x.BillType == 'P' ? '台灣Pay' : ''))}</td>
                                            <td>NT${x.BillMoney}</td>
                                            <td>{x.BillState == '0' ? '待付款' : (x.BillState == '1' ? '已付款' : (x.BillState == '4' ? '已逾期' : ''))}</td>
                                            <td></td>
                                            <td><button type="button" className="btn-ezw-primary btn-sm"
                                                onClick={(e) => getRecBillInfo(x.BillID)}>查看</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="text-center mt-4">
                                <button type="button" id="backPoints2Btn" className="btn-ezw-outline-primary  btn-sm" onClick={backPoints}>回儲值與查詢</button>
                                {/*<button type="button" id="next2Btn" className="btn-ezw-primary  btn-sm ml-3">下一步</button>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" id="confirmDiv" style={{ display: "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <h2 style={{ textAlign: "center" }}>{
                                (
                                    (takPlain == 6 || takPlain == 7 || takPlain == 8)
                                        ?
                                        "會考衝刺專案"
                                        :
                                        "儲值輕鬆幣"
                                )
                            }</h2>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>儲值方案</td><td>{modeTit}</td>
                                    </tr>
                                    <tr>
                                        <td>售價</td><td>{amount}</td>
                                    </tr>
                                    <tr>
                                        <td>付款方式</td><td>{pType}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="text-center mt-4">
                                <button type="button" id="backPointsBtn" className="btn-ezw-outline-primary  btn-sm" onClick={backTopUpMode}>回上一頁</button>
                                <button type="button" id="nextBtn" className="btn-ezw-primary btn-sm ml-3" onClick={sendPoints}>{((takPlain == 6 || takPlain == 7 || takPlain == 8) ? "確定購買" : "確定儲值") }</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" id="modeDetailDiv" style={{ display: "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <h2 style={{ textAlign: "center" }}>ATM繳款資訊</h2>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>儲值方案</td><td>{modeTit}</td>
                                    </tr>
                                    <tr>
                                        <td>售價</td><td>{amount}</td>
                                    </tr>
                                    <tr>
                                        <td>儲值日</td><td>{createDate}</td>
                                    </tr>
                                    <tr>
                                        <td>銀行代碼</td><td> (004) 臺灣銀行</td>
                                    </tr>
                                    <tr>
                                        <td>ATM繳費帳號</td><td>{billBankNo}</td>
                                    </tr>
                                    <tr>
                                        <td>繳款截止日</td><td>{billingDate}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="text-center mt-4">
                                <button type="button" id="backPointsBtn" className="btn-ezw-primary btn-sm" onClick={goRecord}>前往購買紀錄</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" id="payDetailDiv" style={{ display: "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <h2 style={{ textAlign: "center" }}>台灣Pay繳款資訊</h2>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>儲值方案</td><td>{modeTit}</td>
                                    </tr>
                                    <tr>
                                        <td>售價</td><td>{amount}</td>
                                    </tr>
                                    <tr>
                                        <td>儲值日</td><td>{createDate}</td>
                                    </tr>
                                    <tr>
                                        <td>台灣Pay QR Code</td><td><img style={{ width: "250px" }} src={qrcode} /></td>
                                    </tr>
                                    <tr>
                                        <td>繳款截止日</td><td>{billingDate}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="text-center mt-4">
                                <button type="button" id="backPointsBtn" className="btn-ezw-outline-primary btn-sm" onClick={goRecord}>前往購買紀錄</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" id="billDetailDiv" style={{ display: "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <h2 style={{ textAlign: "center" }}>購買紀錄</h2>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>儲值日</td><td>{infoCreateDate}</td>
                                    </tr>
                                    <tr>
                                        <td>付款方式</td><td>{infoBillType == 'C' ? '信用卡' : (infoBillType == 'A' ? 'ATM轉帳' : (infoBillType == 'P' ? '台灣Pay' : ''))}</td>
                                    </tr>
                                    <tr>
                                        <td>金額</td><td>NT${infoBillMoney}</td>
                                    </tr>
                                    {infoBillType != 'A' ?
                                        <tr>
                                            <td>繳費帳號</td><td>{infoBillBankNo}</td>
                                        </tr>
                                        :
                                        ''
                                    }
                                    {infoBillType == 'A' ?
                                        <tr>
                                            <td>ATM繳費帳號</td><td>{infoBillBankNo}</td>
                                        </tr>
                                        :
                                        (infoBillType == 'P' ?
                                            <tr>
                                                <td>台灣Pay QR Code</td><td><img style={{ width: "250px" }} src={infoQrcode} /></td>
                                            </tr>
                                            :
                                            <tr>
                                                <td>信用卡付款</td><td></td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td>繳款截止日</td><td>{infoBillingDate}</td>
                                    </tr>

                                    <tr>
                                        <td>繳費狀態</td><td>{infoBillState}</td>
                                    </tr>
                                    <tr>
                                        <td>繳費方式</td><td>{infoPayType}</td>
                                    </tr>
                                    <tr>
                                        <td>繳費日期</td><td>{infoPayDate}</td>
                                    </tr>
                                    <tr>
                                        <td>繳費金額</td><td>{infoPayMoney}</td>
                                    </tr>
                                    <tr>
                                        <td>贈送點數截止日</td><td>{infoGiftPointsEndDate}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="text-center mt-4">
                                <button type="button" className="btn-ezw-outline-primary btn-sm" onClick={goRecord}>回購買紀錄</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<form method="post" id="creditform" action="https://www.focas.fisc.com.tw/FOCAS_WEBPOS/online/">*/}
                {/*    <input type="hidden" name="MerchantID" value="004242290279001" />*/}
                {/*    <input type="hidden" name="TerminalID" value="90010001" />*/}
                {/*    <input type="hidden" name="merID" value="24229027" />*/}
                {/*    <input type="hidden" name="purchAmt" id="purchAmt" value="" />*/}
                {/*    <input type="hidden" name="lidm" id="lidm" value="" />*/}
                {/*    <input type="hidden" name="AutoCap" value="0" />*/}
                {/*    <input type="hidden" name="AuthResURL" value="https://ez.writingbar.com.tw/api/Credit/Creditlist" />*/}
                {/*</form>*/}

                <form method="post" id="creditform" action="https://www.focas.fisc.com.tw/FOCAS_WEBPOS/orderInquery/">
                    <input type="hidden" name="MerchantID" value="004242290279001" />
                    <input type="hidden" name="TerminalID" value="90010001" />
                    <input type="hidden" name="merID" value="24229027" />
                    <input type="hidden" name="MerchantName" value="" />
                    <input type="hidden" name="purchAmt" id="purchAmt" value="" />
                    <input type="hidden" name="lidm" id="lidm" value="" />
                    <input type="hidden" name="AutoCap" value="0" />
                    <input type="hidden" name="ResURL" value={API_HOST + "/Credit/CheckReCredit"} />
                </form>
            </div>
        </>
    );
}

export default Points;
