import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function About() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const [newsTable, setNewsTable] = useState([
        {
            "title": `New!【✿好評延長✿】註冊會員贈作文輔導，活動延長至112年3月底截止！`,
            "date": `2023/03/03`,
            'content': [
                `輕鬆寫作吧於112年3月正式上線，註冊新會員可獲贈【一般批閱】免費輔導`,
                `廣受好評，現活動延長至112年3月31日 24:00 截止，輕鬆註冊，輕鬆體驗全新的線上作文輔導！`,
                ``,
                `【體驗方案說明】`,
                `輔導方案：一般批閱（分項分數及評語、寫作級分預測）`,
                `使用方式：註冊後重新登入，於會員中心＞<button type="button" class="btn btn-link" onclick="goOrders()">【批閱結果/訂單查詢】</button>可查詢到體驗方案，從<a href="/Service">【求批閱】</a>上傳作文後，最晚五個工作日系統會通知下載寫作成果，可於<button type="button" class="btn btn-link" onclick="goOrders()">【批閱結果/訂單查詢】</button>處查詢並下載。`,
                `體驗期限：體驗方案限於112/04/15前使用完畢。`,
            ]
        },
        {
            "title": `【題目解析】翰林111年國中教育會考模擬測驗寫作測驗解析─〈沉默是金〉`,
            "date": `2023/02/24`,
            'content': [
                `111年國中教育會考模擬測驗（測驗日期：111年12月22日、23日），寫作測驗題目為「漫畫圖說」題型，這類型題目的特色有：`,
                `題目為「漫畫圖說」題型，這類型題目的特色有：`,
                `●省略內容，直接先看題目（避免被引文干擾）書寫`,
                `●看漫畫（和人物對話、意向）延伸思考`,
                `●依題目要求（與圖文互動）設定寫作方針`,
                ``,
                `面對這類型題目，同學們知道該如何破題嗎？不急不急，馬上就跟著文章往下看吧～`,
                ``,
                `一、題目與解題`,
                ``,
                `<img src="images/news/沉默是金寫作題目.jpg" width="100%" height="auto" />`,
                ``,
                `命題看方向(對話式反詰挖掘題意)`,
                `1. 「沉默是金」在一般的價值定義是偏正向還是負面？`,
                `2. 「金」為上品之金屬，由此可以推斷為正向`,
                `3. 題目呈現的對話紀錄中的我所說的：「其實我有親眼看到，只是沒有勇氣說出來。」符合「沉默是金」的正面意嗎？`,
                `4. 沉默是金是指：「保持沉默是最可貴的」；但對任何事情難道都該保持沉默嗎？`,
                `不！保持沉默必須視狀況而定，如果是看見他人面臨危難還保持沉默，拿即是冷漠，更甚者可說是共犯！`,
                `5. 所以對話紀錄中的我所說的：「其實我有親眼看到，只是沒有勇氣說出來。」便是對「沉默是金」的誤解，誤以為遇到任何事只要沉默就是上策。`,
                `6. 題目特地從反面意義去提示，藉以告知考生世人普遍對「沉默是金」有哪些誤解。`,
                `7. 考生已知的正向定義加上題目所引導的負面定義，至少有兩個面向可以下筆敘述對「沉默是金」的看法。`,
                `8. 考生若能從正反二面下筆書寫，文章的內容與論述的層次都會因此豐富許多，對比單線操作的文章會略勝一籌。`,
                ``,
                `<strong>二、組織架構：</strong>`,
                `    行文架構應力求分明，段落布局可依「起、承、轉、合」的模式分層敘述。每個段落所司之職各有不同，可以想像成各種需要團隊合作的工作或運動，每個人所扮演的角色都不相同，所以分工明確就顯得相當重要，在作文上即是「分段」。仍有不少同學會在首尾開合處琢磨太久，置入太多敘述導致此二段失焦，功能發揮不全；此外，中段事件敘述的展開，應著重其層次結構的分明，否則文章脈絡難以順暢，敘事節奏也會紊亂，將大大影響感想發揮的力量。`,
                ``,
                `三、內容：`,
                `    此次測驗，大部分的考生都保守在自己的立場與觀點裡鑽研，對於交互參照其他想法見解的動作比較少去嘗試，使得整體在論述表達上較為單乏無味，很難創出新格與他人有所區隔，以致形成了突破的障礙。`,
                `    題目為「沉默是金」，題目要求我們結合自己的經驗或見聞，寫下對「沉默是金」的感受或想法。題目有兩個重要的要求：一是「結合自己的經驗或見聞」，一是表達對「沉默是金」的感受或想法，這二者缺一不可；許多同學能寫出結構完整且論述流暢的文章，但卻疏忽了最基本的題目要求，未完整呈現上述二項要求，因此被扣上一級分之多，原本可以問鼎六級分的文章最後僅取得五級分，應當於審題時多留心注意題目的要求，務必先滿足題目的規範，否則再好的文思都難創佳績！`,
                `再者，題目已經明示世人普遍對於「沉默是金」的誤解，意味著希望考生能透過經驗來論述這類的現象，從反面的角度來凸顯「沉默是金」的真正意涵，「過與不及」與「刻板印象」常常導致世人在理解真理時產生問題，比如認為沉默就是應對所有事物狀況時最好的反應，抑或是認為冷漠就是殘酷冰冷的行為，任何一丁點都不能被容許！殊不知，沉默和冷酷都有個準值，超過善的沉默過多及變成壞的，不及壞的冷漠則可成為好的(比如人與人之間需要適度的距離，距離產生的冷漠是好的)，是故，論述觀點時切忌掉入「絕對」的窠臼中，任何道理都有其開放性，下筆前冷靜分析其中的可能，將更助於論述上的通暢與深度。`,
        ]
        },
        {
            "title": `【好康活動】現在註冊成為新會員，贈寫作輔導1次，良機稍縱即逝，請勿錯過！`,
            "date": `2023/02/24`,
            'content': [
                `輕鬆寫作吧於112年3月正式上線，3/1前註冊成為新會員，可獲贈【一般批閱】1次，是貨真價值的寫作輔導服務，價值NT120元，機會難得，錯過不再！`,
                ``,
                `【體驗方案說明】`,
                `輔導方案：一般批閱`,
                `使用方式：註冊後重新登入，於會員中心＞【批閱結果/訂單查詢】可查詢到體驗方案，從【求批閱】上傳作文後，最晚五個工作日系統會通知下載寫作成果，可於【批閱結果/訂單查詢】處查詢並下載。`,
                `體驗期限：本方案限於112/03/31前使用。`,
            ]
        }
    ]);
    const [showNews, setShowNews] = useState((-1))
    const [listOut, setListOut] = useState(false)

  useEffect(() => {
      
  }, []);

    function showPost(e) {
        if (e.target.id !== "offPost") {
            let post = e.target.id.replace('post', '');
            if (post < newsTable.length) {
                setShowNews(post);
                document.getElementById('newsHead').classList.add('d-none');
                document.getElementById('newsContent').classList.remove('d-none');
                return null;
            }
        }
        setShowNews((-1));
        document.getElementById('newsHead').classList.remove('d-none');
        document.getElementById('newsContent').classList.add('d-none');

    }
    function fullNews() {
        setListOut(true)
    }

  return (
      <>
          <div className="top"></div>
          <div id="main" className=""
              style={{
                  backgroundImage: "url(images/news/news-bg.jpg)",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  fontSize: "1.2rem",
                  width: " 100%",
                  height: "100vh",
                  position: "relative",
                  paddingTop: "100px",
                  overflowX: "hidden",
              }} >
              <div className="row mb-4 container mx-auto">
                  <div className="col-12 d-flex justify-content-center flex-column align-items-center px-5" style={{ background: "rgba(255,255,255,0.6)", borderRadius: "32px 32px 0 0" }}>
                      <img className="w-auto my-4" src="images/news/news-head.png" alt="最新消息" style={{ height: "3rem", display: "block" }} ></img>
                  </div>
                  {/*新聞標題*/}
                  <div id="newsHead" className="col-12 px-3 px-lg-5" style={{ minHeight: "calc(100vh - 380px)", background: "rgba(255,255,255,0.6)", borderRadius: "0 0 32px 32px" }}>
                      {newsTable.map((value, key) => {
                          if (!listOut) {
                              if (key > 10) return;
                          }
                          return (
                              <div className="border-secondary border-bottom px-3 mb-3">
                                  <p className="h5" id={`post${key}`} onClick={showPost} style={{ fontWeight: "bold", cursor: "pointer" }} >{value.title}</p>
                                  <p className="text-secondary">{value.date}</p>
                              </div>
                          )                          
                      })}
                      <div className={(listOut?"d-none":"")} >
                          <p className="text-right text-secondary-emphasis" style={{ cursor: "pointer" }} onClick={fullNews} >See more&gt;</p>
                      </div>
                      <div className={(listOut ? "" : "d-none")} >
                          <p className="text-center">--無更舊消息--</p>
                      </div>
                  </div>
                  {/*新聞內文*/}
                  <div id="newsContent" className="col-12 d-none" style={{ minHeight: "calc(100vh - 380px)", background: "rgba(255,255,255,0.6)", borderRadius: "0 0 32px 32px" }}>
                      <div className="row aboutTxt justify-content-center mb-5">

                          <div className="col-xl-9 col-md-10 col-12 justify-content-center text-center">
                              <h1 className="w-100 text-left text-white" style={{ backgroundColor: "#5EA9BE", borderRadius: "4px", fontSize: "1.5rem", lineHeight: "3rem" }} >
                                  {(showNews >= 0 ?
                                      newsTable[showNews].title
                                      :
                                      "空白"
                                    )}
                                  
                              </h1>
                              <p className="text-secondary text-left">
                                  {(showNews >= 0 ?
                                      newsTable[showNews].date
                                      :
                                      "----/--/--"
                                  )}
                              </p>
                              <div className="text-left">
                                  {(showNews >= 0 ?
                                      newsTable[showNews].content.map((value) => {
                                          if (value.includes('/')) {
                                              return (
                                                  <div dangerouslySetInnerHTML={{ __html: value }}></div>
                                              )
                                          } else {
                                              return (
                                                  <p> {value} </p>
                                              )
                                          }
                                      })
                                      :
                                      "----/--/--"
                                  )}
                              </div>
                              <button className="btn-ezw-outline-primary" type="button" id="offPost" onClick={showPost} >回最新消息</button>
                          </div>

                      </div>

                  </div>
              </div>
              
              <div className="footer">
                  <div className="row text-center justify-content-center">
                      <div className="col-12 ">
                          <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407040臺中市西屯區長安路一段83號5樓<br />
                              電話：(04)2315-2500 分機815　客服信箱：service@writingbar.com.tw</p>
                      </div>
                  </div>
              </div>
          </div>
     </>
  );
}
 
export default About;
