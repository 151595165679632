import React from 'react';
import ReactDOM from 'react-dom';
import I18n from "./i18n"; //多國語言
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import Home from './pages/Home';
import Header from './components/partial/header';
import About from './pages/About';
import Service from './pages/Service';
import Member from './pages/Member';
import Online from './pages/Online';
import Diagnostic from './pages/Diagnostic';
import General from './pages/General';
import Points from './pages/Points';
import Orders from './pages/Orders';
import Atm from './pages/Atm';
import Exam from './pages/Exam';
import Crs from './pages/Crs';
import News from './pages/News';
import Ideal from './pages/Ideal';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Maintenance from './pages/Maintenance';

const header = (
    <GoogleOAuthProvider clientId="775144696441-li2uhe3l2tijmvkclja9jo7l376j4h55.apps.googleusercontent.com">
    <Header />
</GoogleOAuthProvider>
    
);
ReactDOM.render(header, document.getElementById('header'));
const root = (
    //test
   
    <I18n>
        
        <BrowserRouter>
           
            <Switch>
                {/*<Route exact path="/" component={Maintenance} />*/}
                <Route exact path="/" component={Home} />
                <Route exact path="/About" component={About} />
                <Route exact path="/Service" component={Service} />
                <Route exact path="/Member" component={Member} />
                <Route exact path="/Online" component={Online} />
                <Route exact path="/Diagnostic" component={Diagnostic} />
                <Route exact path="/General" component={General} />
                <Route exact path="/Points" component={Points} />
                <Route exact path="/Orders" component={Orders} />
                <Route exact path="/Atm" component={Atm} />
                <Route exact path="/Exam" component={Exam} />
                <Route exact path="/Crs" component={Crs} />
                <Route exact path="/News" component={News} />
                <Route exact path="/Ideal" component={Ideal} />
            </Switch>
        </BrowserRouter >
        </I18n>
    
);

ReactDOM.render(root, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
