import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_GET_TopUpModeList } from '../global/constants';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Atm() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const [UserData, setUserData] = useState({});
    const [acntName, setAcntName] = useState();
    const [Program, setProgram] = useState();
    const [Pay, setPay] = useState();
    const [pointArray, setPointArray] = useState([{}]);

    useEffect(() => {
        document.getElementById("header").remove();
        if (localStorage.getItem("UserData") != null) {
            setUserData(JSON.parse(localStorage.getItem("UserData")));
            //console.log(JSON.parse(localStorage.getItem("UserData")));

            getData();
        }
        else {
            window.location = "/";
        }
    }, []);

    function getData() {
        //API_GET_TopUpModeList().then((response) => response.data)
        //    .then((data) => {
        //        console.log(data);
        //        setPointArray(data.result);

        //    })
        //    .catch(err => {
        //        console.log(err);
        //        if (err.response.status === 401 || err.response.data.code == "1001") {
        //            localStorage.clear();
        //            history.push('/');
        //        }
        //    })
    }

    function sendPoints() {
        console.log("Program:" + Program);
        console.log("Pay:" + Pay);

        var go = true;
        if (Program == null) {
            go = false;
            alert("請選擇儲值方案");
        } else if (Pay == null) {
            go = false;
            alert("請選擇付款方式");
        }

        if (Pay == '2') {
            console.log("ATM");


        }

    }

  return (
      <>
          <div id="main" className="" style={{ backgroundColor: "#b8dce8",fontSize: "1.2rem",width: "100%",height: "100vh"}} >
              {/*<div className="container" id="pointsDiv">*/}
              {/*    <div className="row justify-content-center" style={{ paddingTop: "20vh"}} >*/}
              {/*        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem"}}>*/}

              {/*              */}{/*<div className="col-12">*/}
              {/*            <h2 style={{ textAlign: "center" }}><img src="images/儲值查詢.svg" style={{ width: "3.5rem" }} />儲值查詢</h2>*/}
              {/*            <hr />*/}
              {/*            <div>輕鬆幣： 0 &emsp;<button type="button" id="storedValueBtn" className="btn btn-sm" style={{ backgroundColor: "#5daabe", borderColor: "#5daabe", color: "white" }} onClick={showStoredValue}>儲值</button></div>*/}
              {/*            <div>紅利點數： 0</div>*/}
              {/*            <div>*/}
              {/*                儲值紀錄：您有  <span style={{ color: "red" }} >2</span>  筆儲值紀錄*/}
              {/*                &emsp;<button type="button" id="recordBtn" className="btn btn-sm" style={{ backgroundColor: "#5daabe", borderColor: "#5daabe", color: "white" }} onClick={showRecord}>查詢</button>*/}
              {/*            </div>*/}
              {/*            <hr />*/}
              {/*            <div><button type="button" className="btn btn-link" onClick={showEzMsg} >輕鬆幣</button>：用來支付作文輔導服務的方式，可儲值。</div>*/}
              {/*            <div><button type="button" className="btn btn-link" onClick={showPointMsg} >會員點數</button>：可用於本網站付費服務時部分折抵，折抵金額以服務頁面所示為主。</div>*/}

              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}


              <div id="paymentDiv" style={{ margin: ".5rem" }}>
                  {/*minHeight: "9rem", margin: "0"*/}
                  <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.7rem", margin: ".5rem 0" }}>繳費單</p>
                  <table id="atmTable">
                      <tr>
                          <td colspan="4" className="text-left borderNone">日期：</td>
                          <td colspan="4" className="text-right borderNone">第一聯：繳款人收執聯</td>
                      </tr>
                      <tr>
                          <th>姓名</th>
                          <td></td>
                          <th>電話</th>
                          <td></td>
                          <td colspan="3" className="text-center bg-light font-weight-bolder"></td>
                      </tr>
                      <tr>
                          <th>類組</th>
                          <td colspan="3"></td>
                          <td colspan="3" rowspan="5" width="40%">

                          </td>
                      </tr>
                      <tr> <th>繳費代碼</th> <td colspan="3"></td> </tr>
                      <tr> <th>繳費期限</th> <td colspan="3"> 前</td> </tr>
                      <tr> <th>繳費金額</th> <td colspan="3" className="font-weight-bolder"> NT$  元整 </td> </tr>
                      <tr> <th>合計</th> <td colspan="3" className="font-weight-bolder"> 新台幣  整 </td> </tr>
                      <tr>
                          <td colspan="4" style={{ height: "5rem;" }} >
                              <ul id="atmUl">
                                  <li>每位報名者皆有個人專屬繳費代號，請勿使用他人報名表進行繳費。</li>
                                  <li>於實體/網路 ATM 輸入繳費代碼即可繳費。<small>(若為跨行繳款，須自行負擔手續費 15 元。)</small><br/><b>銀行代碼： (004) 臺灣銀行<br/>轉入帳號：</b></li>
                                  <li>請使用 A4 尺寸白色紙張單面列印，並使用列印品質較優的印表機輸出進行繳費。</li>
                                  <li>繳費完成後請妥善保存收執聯 & 繳費證明。</li>
                              </ul>
                          </td>
                          <td colspan="4" className="text-center" ></td>
                      </tr>
                  </table>
              </div>
          </div>
     </>
  );
}
 
export default Atm;
