import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_GET_SumBill, API_GET_SumProjectBill } from '../global/constants';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Service() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

    const [UserData, setUserData] = useState({});

    const [Bill, setBill] = useState({ SumPoint: 0, BuyCount: 0, SumGiftPoint: 0 }); //SumPoint:輕鬆幣, BuyCount:幾筆購買紀錄, SumGiftPoint:紅利點數
    const [ProjectBill, setProjectBill] = useState({ ToupCaption: '', Point: 0 }); //會考衝刺專案 ToupCaption:大神預測專案/臨陣磨槍專案/登峰造極專案, Point:次數

    const [PointEndDate, setPointEndDate] = useState(''); //到期日期
    const [RecBill, setRecBill] = useState({ SumOrder: 0 }); //幾筆購買紀錄
    const [UserPointList, setUserPointList] = useState([{}]); //輕鬆幣, 紅利點數, 會考衝刺專案
    const [firstGift, setFirstGift] = useState([{}]); //體驗方案
/*    const [firsTry, setFirsTry] = useState(true); *///體驗方案顯示 可以設定為false僅登入後才顯示此按鈕

  useEffect(() => {
      if (localStorage.getItem("UserData") != null) {
          setUserData(JSON.parse(localStorage.getItem("UserData")));

          getPoints();
      } else {

          const upUB = setInterval(() => {
              if (localStorage.getItem("UserData") != null) {
                  setUserData(JSON.parse(localStorage.getItem("UserData")));

                  getPoints();

                  clearInterval(upUB);
              } else {
              }
          }, 100);
      }
  }, []);

    function getPoints() {
        API_GET_SumBill().then((response) => response.data)
            .then((data) => {
                console.log(data.result);
                setBill(data.result);

                var exDate = '';
                if (data.result.expiring.length > 0) {
                    exDate = data.result.expiring[0].PointEndDate.split('T')[0];
                }
                //console.log(exDate);

                setPointEndDate(exDate);
                setRecBill(data.result.recBill[0]);
                setUserPointList(data.result.userPoint);
                if (data.result.firstGiftPoint) {
                    setFirstGift(data.result.firstGiftPoint);
                }
                
                /*體驗方案僅登入顯示
                if (Object.keys(data.result).includes('firstGiftPoint')) {
                    data.result.firstGiftPoint.map((value) => (
                        value.PayPoint == 0 ? setFirsTry(true) : null
                    ))
                }*/
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            });
    }

    function buyNow(direct) {
        if (/^\d{1,}$/.test(direct)) {
            direct = parseInt(direct);
            //console.log("direct:" + direct);
            if (localStorage.getItem("UserData") != null) {
                //基本方案
                if (direct == 1 || direct == 2 || direct == 3) {
                    var nPoint = 0; //輕鬆幣門檻
                    var ngPoint = 0; //輕鬆幣+紅點點數用的 輕鬆幣門檻
                    var gPoint = 0; //紅利點數數門檻

                    if (direct == 1) {
                        nPoint = 700;
                        ngPoint = 630;
                        gPoint = 700;
                    } else if (direct == 2) {
                        nPoint = 350;
                        ngPoint = 315;
                        gPoint = 350;
                    } else if (direct == 3) {
                        nPoint = 120;
                        ngPoint = 108;
                        gPoint = 120;
                    }

                    var SumPoint = 0; //輕鬆幣
                    var SumGiftPoint = 0; //紅利點數

                    UserPointList.map((x, i) => (
                        x.PointType == 'P'
                            ?
                            SumPoint = parseInt(x.LastPoint) || 0
                            :
                            (x.PointType == 'G' ? SumGiftPoint = parseInt(x.LastPoint) || 0 : 0)
                    ))

                    console.log(SumPoint);
                    //SumPoint = 330;
                    //SumGiftPoint = 180;

                    var minusGP = 0;//可扣除紅利
                    if (gPoint > SumGiftPoint && SumGiftPoint > 0) {
                        minusGP = Math.floor(SumGiftPoint / 100) * 10//每次最小扣除紅利單位100
                        console.log(minusGP);
                    } else if (gPoint < SumPoint) {
                        minusGP = gPoint / 10
                    }

                    //if (SumPoint < nPoint || (SumPoint < ngPoint && SumGiftPoint < gPoint)) {//只能扣0或最大紅利點
                    if ((SumPoint + minusGP) < nPoint) {
                        var ct = `<div>本次批閱申請需要 ${nPoint} 輕鬆幣</div>` //或 630輕鬆幣+700紅利點數
                            + `<div>剩餘輕鬆幣：${SumPoint}<br>`
                            + `剩餘紅利點數：${SumGiftPoint}`
                            + `<br><br><a href="../Points">前往會員中心儲值</a></div>`;

                        $.confirm({
                            title: '',
                            content: ct,
                            theme: 'material',
                            type: 'blue',
                            columnClass: 'col-sm-6',
                            animation: 'zoom',
                            closeAnimation: 'scale',
                            buttons: {
                                取消: { action: function () { } },
                                tryAgain: {
                                    text: 'OK',
                                    btnClass: 'btn-blue',
                                    action: function () {

                                    }
                                },
                            },
                        });

                    } else {
                        switch (direct) {
                            //一般
                            case 1:
                                window.location.href = "Online";
                                break;
                            case 2:
                                window.location.href = "Diagnostic";
                                break;
                            case 3:
                                window.location.href = "General";
                                break;
                        }
                    }
                }
                //會考專案
                else if (direct == 6 || direct == 7 || direct == 8) {
                    var goUrl = ""; //前往
                    for (let i of UserPointList) {
                        if (i.CodeText == "大神預測專案") {
                            if (direct == 6) {
                                if (i.LastPoint > 0) {
                                    goUrl = "General?plain=exam";

                                } else {
                                    goUrl = "/Exam?plain=" + direct;
                                }
                            }
                        }
                        else if (i.CodeText == "臨陣磨槍專案") {
                            if (direct == 7) {
                                if (i.LastPoint > 0) {
                                    goUrl = "Diagnostic?plain=exam";
                                } else {
                                    goUrl = "/Exam?plain=" + direct;
                                }
                            }
                        }
                        else if (i.CodeText == "登峰造極專案") {
                            if (direct == 8) {
                                if (i.LastPoint > 0) {
                                    goUrl = "Online?plain=exam";
                                } else {
                                    
                                }
                            }
                        }
                    }
                    if (goUrl) {
                        window.location.href = goUrl;
                    } else {
                        if (direct == 6) {
                            window.location.href = "/Exam?plain=" + direct;
                        } else if (direct == 7) {
                            window.location.href = "/Exam?plain=" + direct;
                        } else if (direct == 8) {
                            window.location.href = "/Exam?plain=" + direct;
                        } else {
                            alert('網路異常：登入確認失敗')
                        }
                    }
                }
                //體驗方案 (0322佑禎:改 體驗方案入口 進去後會看到 體驗方案 一對一線上輔導體驗 診斷式批閱體驗 一般批閱體驗)
                else if (direct == 9) {

                    //如果有 任一種體驗方案次數 就可以進到這頁
                    window.location.href = "Experience";
                    

                    //let firsTime = false;
                    //if (firstGift.length > 0 && Object.keys(firstGift[0]).length>0) {
                    //    firstGift.map((value) => (
                    //        value.PayPoint == 0 ? firsTime=true : null
                    //    ))
                    //}
                    //if (firsTime) {
                    //    window.location.href = "General?plain=firstTry";
                    //} else {
                    //    var ct = `<div>您已使用完畢，請試試其他方案</div>`
                    //        + `<br>或 <a href="../Points">前往會員中心儲值</a></div>`;

                    //    $.confirm({
                    //        title: '',
                    //        content: ct,
                    //        theme: 'material',
                    //        type: 'blue',
                    //        columnClass: 'col-sm-6',
                    //        animation: 'zoom',
                    //        closeAnimation: 'scale',
                    //        buttons: {
                    //            取消: { action: function () { } },
                    //            tryAgain: {
                    //                text: 'OK',
                    //                btnClass: 'btn-blue',
                    //                action: function () {

                    //                }
                    //            },
                    //        },
                    //    });
                    //}
                }
            } else {
                $('#sys_signin').modal('show')
            }
        }
    }

  return (
      <>
          <div className="top"></div>
          <div id="main" className="" style={{
              backgroundImage: "url(images/service/service-bg.jpg)",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              fontSize: "1.2rem",
              width: "100%",
              height: "100vh",
              position: "relative",
              padding: "15vh calc(50vw - 500px) 0"
          }} >
              <div className="row mx-0 mx-md-5 justify-content-center" style={{ backgroundColo: "#FFF", margin: "0" }}>
                  <div className="col-md-9 col-12 text-center">
                      <img className="w-100" src="images/service/now-action.png" />
                  </div>
                  <div className="col-md-8 col-12 text-center">
                      
                      <div className="row justify-content-center align-items-start py-3 px-md-0 px-2" style={{ overflowX: " hidden", overflowY: "auto", height: "calc(72vh - 100px)" }}>
                          {/*{(firsTry*/}
                          {/*    ?*/}
                          {/*    <div className="col-md-11 pb-4">*/}
                          {/*        <img className="w-100" src="images/promotion/freePlainEnter.png" style={{ maxWidth: "283px", cursor: "pointer" }} onClick={(e) => buyNow(9)} />*/}
                          {/*    </div>*/}
                          {/*    : "")}*/}
                          
                          <div className="col-md-11 servicePlain">
                              <a className="headA" onClick={(e) => buyNow(1)}>
                                  <span>一對一線上輔導</span>
                              </a>
                              <span className="serviceIntro" id="Program1" style={{ backgroundImage:"url(images/service/01-x.png)" }} >方案介紹</span>
                          </div>

                          <div className="col-md-11 servicePlain mt-md-5 mt-4">
                              <a className="headA" onClick={(e) => buyNow(2)}>
                                  <span>診斷式批閱</span>
                              </a>
                              <span className="serviceIntro" id="Program2" style={{ backgroundImage: "url(images/service/02-x.png)" }} >方案介紹</span>
                          </div>

                          <div className="col-md-11 servicePlain mt-md-5 mt-4">
                              <a className="headA" onClick={(e) => buyNow(3)}>
                                  <span>一般批閱</span>
                              </a>
                              <span className="serviceIntro" id="Program3" style={{ backgroundImage: "url(images/service/03-x.png)" }} >方案介紹</span>
                          </div>

                          <div className="col-md-11 mt-md-5 mt-4 servicePromte p-0" style={{boxShadow:" 10px 10px 4px -7px #00000075"}} >
                              <h3 className="promteTitle">會考衝刺專案</h3>
                              <div className="d-flex px-2 py-lg-2 py-1 flex-column flex-lg-row align-items-center">
                                  <a className="col-lg-4 col-8 text-decoration-none" onClick={(e) => buyNow(6)} >大神預測</a>
                                  <a className="col-lg-4 col-8 text-decoration-none" onClick={(e) => buyNow(7)} >臨陣磨槍</a>
                                  <a className="col-lg-4 col-8 text-decoration-none" onClick={(e) => buyNow(8)} >登峰造極</a>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>

              <div className="footer fixed-bottom">
                  <div className="row text-center justify-content-center">
                      <div className="col-12">
                          <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407040臺中市西屯區長安路一段83號5樓<br />
                              電話：(04)2315-2500 分機815　客服信箱：service@writingbar.com.tw</p>
                      </div>
                  </div>
              </div>
          </div>
     </>
    );
}
 
export default Service;
