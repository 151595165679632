import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from '../global/TokenUtil';
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_GET_TopUpModeList } from '../global/constants';
//圖片
import adPic1 from '../images/promotion/sprint/01.png';
import adPic2 from '../images/promotion/sprint/02.png';
import adPic3 from '../images/promotion/sprint/03.png';
import adPicHead from '../images/promotion/sprint/headline.png';
import adPicBg from '../images/promotion/sprint/bg.jpg';
import adPic4 from '../images/promotion/sprint/p01.jpg';
import adPic5 from '../images/promotion/sprint/p02.jpg';
import adPic6 from '../images/promotion/sprint/p03.jpg';

function Exam() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };

    const [plain, setPlain] = useState('')
    const [UserData, setUserData] = useState({});


    useEffect(() => {
        let urltak = queryParams.get('plain');
        if (urltak) {
            urltak = parseInt(urltak);
            if (urltak >= 6 && urltak <= 8) {
                detailSPromt(true, urltak);
            }
        }

        if (localStorage.getItem("UserData") != null) {
            setUserData(JSON.parse(localStorage.getItem("UserData")));
        }
    }, []);

    //選擇->顯示特定方案
    function detailSPromt(booltxt, sheet) {
        
        if (booltxt === 'true' || booltxt === true) {
            booltxt = true;
        } else {
            booltxt = false;
        }

        if (booltxt) {

            document.getElementById('sellCard').style.display = "none";
            document.getElementById('footer').style.display = "none";
            document.getElementById('sellSheet').classList.remove("d-none");

            if (/^\d{1,}$/.test(sheet)) {
                sheet = parseInt(sheet);
            } else {
                sheet = 0;
            }

            if (6 <= sheet && sheet <= 8) {
                setPlain(sheet);
                console.log('sellSheet' + sheet)
                console.log(sheet)
                document.getElementById('sellSheet' + sheet).classList.remove("d-none");
            } else {
                document.getElementById('sellCard').style.removeProperty("display");
                document.getElementById('footer').style.removeProperty("display");
                document.getElementById('sellSheet').classList.add("d-none");
            }

        } else {
            document.getElementById('sellCard').style.removeProperty("display");
            document.getElementById('footer').style.removeProperty("display");
            document.getElementById('sellSheet').classList.add("d-none");
        }
    }

    //前往結帳流程頁面
    function buyNow() {
        
        if (localStorage.getItem("UserData") != null) {
            location.href = "Points?plain=" + plain;
        } else {
            $('#sys_signin').modal('show')
        }
    }

    return (
        <>
            <div className="top"></div>
            <div id="main" className="" style={{
                backgroundImage: `url(${adPicBg})`,
                backgroundPosition: "center center;",
                backgroundSize: "cover",
                fontsize: "1.2rem",
                width: "100%",
                height: "100vh",
                position: "relative",
                overflowX: "hidden",
                overflowY: "visible",
                padding:"15vh calc(50vw - 500px) 0"

            }} >
                {/*<!--初始畫面顯示-->*/}
                <div className="row justify-content-center flex-lg-reverse flex-row-reverse" id="sellCard">
                    <div className="col-12 pb-xl-5 pb-md-3 pb-2" align="center">
                        <img src={adPicHead} alt="會考衝刺專案" style={{ maxHeight: "125px", width: "100%", maxWidth: "544px" }} />
                    </div>
                    <div className="col-12 col-lg-4 p-0" align="center">

                        <div className="card border-0 rounded-0" align="center" style={{ background: "none" }}>
                            <div>
                                <img src={adPic3} className="card-img-top" alt="登峰造極專案" style={{ marginLeft: "12%" }} />
                            </div>

                            <div className="card-body">
                                <button type="button" className="card-title btn-ezw-primary" onClick={ (e)=>detailSPromt(true,8) } >了解詳情</button>
                            </div>
                        </div>

                    </div>

                    <div className="col-12 col-lg-4 p-0" align="center">

                        <div className="card border-0 rounded-0" style={{ background: "none" }}>
                            <img src={adPic2} className="card-img-top" style={{ margin: "0 auto" }} alt="臨陣磨槍專案" />
                            <div className="card-body">
                                <button id="qqq" type="button" className="card-title btn-ezw-primary" onClick={(e) => detailSPromt(true, 7)}>了解詳情</button>
                            </div>
                        </div>

                    </div>

                    <div className="col-12 col-lg-4 p-0" align="center">

                        <div className="card border-0 rounded-0" style={{ background: "none" }}>
                            <img src={adPic1} className="card-img-top" style={{ margin: "0 auto" }} alt="大神預測專案" />
                            <div className="card-body">
                                <button type="button" className="card-title btn-ezw-primary" onClick={(e) => detailSPromt(true, 6)}>了解詳情</button>
                            </div>
                        </div>

                    </div>

                </div>
                {/*<!--初始畫面顯示 END-->*/}

                {/*<!--顯示銷售方案細節-->*/}
                <div className="row d-none mx-0 mx-md-5" style={{ backgroundColo: "#FFF", margin: "-12vh 0 0" }} id="sellSheet">

                    <div id="sellSheet8" className={(plain == 8 ? "col-12" : "d-none col-12")} style={{ background: "#fff", paddingTop: "12vh", height:"100vh" }} >
                        <div className="card border-0 rounded-0" align="center" style={{ background: "none" }}>
                            <div>
                                <img src={adPic6} className="card-img-top" alt="登峰造極專案細節介紹" style={{ margin: "0 auto 0 auto", maxHeight: "650px", width: "auto", maxWidth: "100%" }} />
                            </div>
                            <div className="card-body">
                                <button type="button" className="card-title btn-ezw-outline-primary mx-2" onClick={(e) => detailSPromt(false)} >返回</button>
                                <button type="button" className="card-title btn-ezw-primary mx-2" onClick={buyNow} >立即購買</button>
                            </div>
                        </div>
                    </div>

                    <div id="sellSheet7" className={(plain == 7 ? "col-12" : "d-none col-12")} style={{ background: "#fff", paddingTop: "12vh", height: "100vh" }} >
                        <div className="card border-0 rounded-0" align="center" style={{ background: "none" }}>
                            <img src={adPic5} className="card-img-top" alt="臨陣磨槍專案" style={{ margin: "0 auto 0 auto", maxHeight: "650px", width: "auto", maxWidth: "100%" }} />
                            <div className="card-body">
                                <button type="button" className="card-title btn-ezw-outline-primary mx-2" onClick={(e) => detailSPromt(false)} >返回</button>
                                <button type="button" className="card-title btn-ezw-primary mx-2" onClick={buyNow} >立即購買</button>

                            </div>
                        </div>

                    </div>

                    <div id="sellSheet6" className={(plain == 6 ? "col-12" : "d-none col-12")} style={{ background: "#fff", paddingTop: "12vh", height: "100vh" }} >
                        <div className="card border-0 rounded-0" align="center" style={{ background: "none" }}>
                            <img src={adPic4} className="card-img-top" alt="大神預測專案" style={{ margin: "0 auto 0 auto", maxHeight: "650px", width: "auto", maxWidth:"100%"}} />
                            <div className="card-body">
                                <button type="button" className="card-title btn-ezw-outline-primary mx-2" onClick={(e) => detailSPromt(false)} >返回</button>
                                <button type="button" className="card-title btn-ezw-primary mx-2" onClick={buyNow} >立即購買</button>

                            </div>
                        </div>

                    </div>

                    
                </div>
                {/*<!--顯示銷售方案細節 END-->*/}

                <div className="footer fixed-bottom" style={{ position: "relative", paddingTop: "72px" }} id="footer">
                    <div className="row text-center justify-content-center">
                        <div className="col-12 ">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407040臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500 分機815　客服信箱：service@writingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>




        </>
    );
}

export default Exam;
