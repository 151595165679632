import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { checkAuthToken } from '../global/TokenUtil';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Crs() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const Code = queryParams.get("Code");
    const base64 = queryParams.get("Base64");

    
    //const [Res, setRes] = useState([{ cid: 1, AccountNo: "3888883052998002", purchAmt: 1, xid: "O-OBJECT-20230218204916.265-5747", lastPan4: "0304", authCode: "004175", txcode: "0-00" },
    //    { cid: 2, AccountNo: "3888883052998002", purchAmt: 1, xid: "O-OBJECT-20230218204916.265-5747", lastPan4: "0304", authCode: "004175", txcode: "0-00" }]);

    const [Res, setRes] = useState([{ AccountNo: '', xid: '', lastPan4: '', authCode: '', CodeText: '' }]);

    useEffect(() => {
        //document.getElementById("header").remove();
        //base64 = 'W3siQ29kZVRleHQiOiJcdTRFQTRcdTY2MTNcdTYyMTBcdTUyOUYiLCJBY2NvdW50Tm8iOiIzODg4MjczMDYxOTk4MDA5KE5ULjEpIiwieGlkIjoiTy1PQkpFQ1QtMjAyMzAyMjcxMDMwNDguMjMyLTMyMTciLCJsYXN0UGFuNCI6IjYxNTkiLCJhdXRoQ29kZSI6IjQwNzk2NCJ9XQ==';
        //console.log(base64);
        if (Code != null) {
            alert("刷卡異常");
        }

        if (base64 != null) {
            var abc = decodeURIComponent(escape(window.atob(base64)));
            //console.log(abc);

            setRes(JSON.parse(abc));

        }

        getData();
        
    }, []);

    function getData() {


    }

    return (
        <>
            <div id="main" className="" style={{ backgroundColor: "#b8dce8", fontSize: "1.2rem", width: "100%", height: "100vh" }} >
                <div className="container" id="pointsDiv">
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>

                            <h2 style={{ textAlign: "center" }}>信用卡繳費</h2>
                            <div className="ml-5">
                            {Res.map((x, i) => (
                                <ul>
                                    <li style={{ listStyleType: "none" }}>交易結果：{x.CodeText}</li>
                                    <li style={{ listStyleType: "none" }}>繳費帳號：{x.AccountNo}</li>
                                    <li style={{ listStyleType: "none" }}>３Ｄ交易序號：{x.xid}</li>
                                    <li style={{ listStyleType: "none" }}>信用卡後四號：{x.lastPan4}</li>
                                    <li style={{ listStyleType: "none" }}>交易授權碼：{x.authCode}</li>
                                </ul>
                            ))}
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <a id="backServiceBtn" className="btn-ezw-primary btn-sm mx-3" href="../Points">回儲值與查詢</a>
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default Crs;
