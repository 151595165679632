import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import "../css/bootstrap.min.css";
import "../js/jquery.min.js";
import ReactDOM from 'react-dom';

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GET_AcntList,
    API_PUT_EditUser,
    API_GET_CompositionList,
    API_GET_OrderQunInFo,
    API_GET_OrderAnsInFo,
    API_GET_File_OrderImpInFo
} from '../global/constants';
import { checkAuthToken } from '../global/TokenUtil';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Orders() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [UserData, setUserData] = useState({});
    //const [UB, setUB] = useState({});

    const [orderList, setOrderList] = useState([{}]);

    //單筆訂單
    const [SingleOrder, setSingleOrder] = useState([{}]);

    const [SingleCodeText, setSingleCodeText] = useState();
    const [SingleOrderQun, setSingleOrderQun] = useState();
    const [ImgList, setImgList] = useState([{}]);
    const [ImgList2, setImgList2] = useState([{}]);

    //訂單審核結果
    const [stutasDetailData, setStutasDetail] = useState([]);

    //顯示區塊控制
    const [viewDiv, setViewDiv] = useState("pointsDiv");

    useEffect(() => {

        if (localStorage.getItem("UserData") != null) {
            setUserData(JSON.parse(localStorage.getItem("UserData")));

            console.log(JSON.parse(localStorage.getItem("UserData")));

            getData();

        }
        else {
            window.location = "/";
        }
    }, []);

    function getData() {
        API_GET_CompositionList().then((response) => response.data)
            .then((data) => {
                //取得列表
                console.log(data.result);
                setOrderList(data.result);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //預覽單筆寫作
    function GetCompositionInfo(orderID) {
        console.log(orderID);

        //取得訂單文字
        API_GET_OrderQunInFo({
            OrderID: orderID
        }).then((response) => response.data)
            .then((data) => {
                console.log('--------------------------');
                console.log(data.result);
                if (data.code == "0000") {
                    setSingleCodeText(data.result[0].CodeText);
                    setSingleOrderQun(data.result[0].OrderQun);
                    setImgList(data.result);

                    setViewDiv("billDetailDiv");
                }
                else {
                    alert(data.message);
                    setSingleCodeText('');
                    setSingleOrderQun('');
                    setImgList([{}]);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

        //取得訂單圖片
        API_GET_OrderAnsInFo({
            OrderID: orderID
        }).then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setImgList2(data.result);

                    console.log('--------------------------');
                    console.log(data.result);
                }
                else {
                    alert(data.message);
                    setImgList2([{}]);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
        //取得審核圖片
        API_GET_File_OrderImpInFo({
            OrderID: orderID
        }).then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setStutasDetail(data.result);

                    console.log('--------------------------');
                    console.log(data.result);
                }
                else {
                    alert(data.message);
                    setStutasDetail([]);
                }
            })
            .catch(err => {
                setStutasDetail([]);
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //控制div
    function BackOrder() {
        setViewDiv("pointsDiv");
    }

    return (
        <>
            <div id="main" className="" style={{ backgroundColor: "#b8dce8", fontSize: "1.2rem", width: "100%", height: "100vh", paddingBottom: "72px", overflowY: "scroll" }} >
                <div className="container" id="pointsDiv" style={{ display: viewDiv == "pointsDiv" ? "block" : "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <h2 style={{ textAlign: "center" }}><img src="images/訂單查詢.svg" style={{ width: "3.5rem" }} />批閱結果/訂單查詢</h2>

                            <table className="table" id="orderTable">
                                <thead id="orderTableHead">
                                    <tr>
                                        <th scope="col" style={{ width: "10%" }}>日期</th>
                                        <th scope="col" style={{ width: "15%" }}>類型/專案</th>
                                        <th scope="col" style={{ width: "20%" }}>作文題目</th>
                                        {/*<th scope="col" style={{ width: "15%" }}>預覽</th>*/}
                                        <th scope="col">狀態</th>
                                        <th scope="col">批閱結果</th>
                                        <th scope="col">語音講解連結</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody id="orderTableBody">
                                    {orderList.map((x, i) => (
                                        <tr>
                                            <td>{x.OrderDate}</td>
                                            <td>{x.CodeText}</td>
                                            <td>{x.OrderQun}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <button data-toggle="modal"
                                                    href="#preViewBlock"
                                                    className="btn btn-sm"
                                                    style={{
                                                        backgroundColor: "#5daabe",
                                                        borderColor: "#5daabe",
                                                        color: "white"
                                                    }}
                                                    onClick={(e) => GetCompositionInfo(x.OrderID)} >查看
                                                </button>

                                            </td>
                                            {/*<td>{(x.OrderType === '1' ? 上傳完成 : (x.OrderType === '2' ? 批閱完成 :))}</td>*/}
                                            {/*<td>批閱成績</td>*/}
                                            {/*<td>語音講解連結</td>*/}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="container" id="billDetailDiv" style={{ display: viewDiv == "billDetailDiv" ? "block" : "none" }}>
                    <div className="row justify-content-center" style={{ paddingTop: "20vh" }} >
                        <div className="col-lg-10 col-12" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem" }}>
                            <div className="row border-bottom" style={{ margin: " 0 auto" }}>
                                <div className="col-12">
                                    <h2 style={{ textAlign: "center" }}>訂單內容</h2>
                                </div>
                                {/*<div className="row">*/}
                                <div className="col-2  border-top">
                                    方案
                                </div>
                                <div className="col-10 border-top">
                                    {SingleCodeText}
                                </div>
                                <div className="col-2  border-top">
                                    作文題目
                                </div>
                                <div className="col-10  border-top">
                                    {SingleOrderQun}
                                </div>
                                <div className="col-2  border-top">
                                    題目圖檔
                                </div>
                                <div className="col-10  border-top">
                                    {/*<span className={() => { SingleCodeText }}>*/}
                                    {ImgList2.map((x) => (
                                        <>
                                            <img src={x.ATTFPath} className="viewImg" data-fancybox={String(x.ImageBase64).split(`_`)[4]} alt={String(x.ImageBase64).split(`_`)[4]} style={{ maxWidth: "100%" }}></img>
                                            <br />
                                        </>
                                    ))}
                                    {/*</span>*/}
                                </div>
                                <div className="col-2  border-top">
                                    作文圖檔
                                </div>
                                <div className="col-10  border-top">
                                    {/*<span className="orderDemoPic">*/}
                                    {ImgList.map((x) => {
                                        if (x && x.ATTFPath) {
                                            return (
                                                <>
                                                    <img src={x.ATTFPath} className="viewImg" data-fancybox={String(x.ImageBase64).split(`_`)[4]} alt={String(x.ImageBase64).split(`_`)[4]} style={{ maxWidth: "100%" }}></img>
                                                    <br />
                                                </>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                    {/*</span>*/}
                                </div>
                                {/*</div>*/}
                                <div className="col-12">
                                    <h2 className="m-0 py-2" style={{ textAlign: "center" }}>批改結果</h2>
                                </div>
                                <div className="col-2  border-top">
                                    批改圖檔
                                </div>
                                <div className="col-10  border-top">
                                    {
                                        stutasDetailData == null || stutasDetailData.length == 0 ? null :
                                            stutasDetailData.map((x, i) =>
                                                Object.keys(x).length > 0 && x.ATTFPath ?
                                                    <>
                                                        <img src={x.ATTFPath} className="viewImg" data-fancybox={String(x.ImageBase64).split(`_`)[4]} alt={String(x.ImageBase64).split(`_`)[4]} style={{ maxWidth: "100%" }}></img>
                                                        <br />
                                                    </>
                                                    : null
                                            )
                                    }
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button type="button" id="backPoints2Btn" className="btn btn-sm" style={{ backgroundColor: "white", borderColor: "#5daabe", color: "#5daabe" }} onClick={BackOrder}>回訂單查詢</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}


export default Orders;
