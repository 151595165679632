import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_GET_AcntList, API_PUT_EditUser } from '../global/constants';
import { checkAuthToken } from '../global/TokenUtil';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Member() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [UserData, setUserData] = useState({});
    const [UB, setUB] = useState({});
    const [acntName, setAcntName] = useState();
    const [userPhone, setUserPhone] = useState();

    const [BirYList, setBirYList] = useState([]);
    const [BirMList, setBirMList] = useState([]);

    const [birYear, setBirYear] = useState();
    const [birMonth, setBirMonth] = useState();

    const [AcntArray, setAcntArray] = useState({});

    useEffect(() => {
        //setUserData(JSON.parse(localStorage.getItem("UserData")));
        if (localStorage.getItem("UserData") != null) {
            

            setUserData(JSON.parse(localStorage.getItem("UserData")));
            //console.log(JSON.parse(localStorage.getItem("UserData")));

            

            setAcntName(JSON.parse(localStorage.getItem("UserData")).acntName);
            setUserPhone(JSON.parse(localStorage.getItem('UB')).userPhone);
            //console.log(JSON.parse(localStorage.getItem('UB')).userPhone);

            setUB(JSON.parse(localStorage.getItem('UB')));
            var birtemp = '';
            if (JSON.parse(localStorage.getItem('UB')).birDay) {
                birtemp = JSON.parse(localStorage.getItem('UB')).birDay.split('T')[0];
            }
            var dateList = birtemp.split('-');
            if (dateList.length < 2) {
                alert("日期錯誤");
            }

            setBirYear(dateList[0]);

            if (dateList.length>1 && dateList[1].length == 2) {
                if (dateList[1].substring(0, 1) == '0') {
                    setBirMonth(dateList[1].substring(1, 2));
                } else {
                    setBirMonth(dateList[1]);
                }
            }

            const myDate = new Date();
            const currYear = myDate.getFullYear(); //西元年
            
            let yLists = [];
            for (var i = 1940; i <= currYear; i++) {
                //記得在JSX中使用JS變數要用花括號包著
                yLists.push(i);
            }
            setBirYList(yLists);

            let monthLists = [];
            for (let j = 1; j <= 12; j++) {
                monthLists.push(j);
            }
            setBirMList(monthLists);

            getData();
        }
        else {
            window.location = "/";
        }
    }, []);

    function getData() {

        //console.log(JSON.parse(localStorage.getItem("UserData")).acntID);

        API_GET_AcntList({
            AcntID: JSON.parse(localStorage.getItem("UserData")).acntID
        }).then((response) => response.data)
            .then((data) => {
                //console.log(data);
                //setAcntArray(data.result);

                setAcntName(data.result[0].AcntName);
                setUserPhone(data.result[0].UserPhone);

                var birtemp = data.result[0].BirDay.split('T')[0];
                var dateList = birtemp.split('-');
                if (dateList.length < 2) {
                    alert("日期錯誤");
                }

                setBirYear(dateList[0]);

                if (dateList[1].length == 2) {
                    if (dateList[1].substring(0, 1) == '0') {
                        setBirMonth(dateList[1].substring(1, 2));
                    } else {
                        setBirMonth(dateList[1]);
                    }
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function upData() {
        //console.log("acntID:" + JSON.parse(localStorage.getItem("UserData")).acntID);
        //console.log(JSON.parse(localStorage.getItem("UserData")));

        var go = true;
        if (acntName == '') {
            go = false;
            alert("請填寫使用者名稱");
        } else if (birYear == '') {
            go = false;
            alert("請選擇出生年");
        } else if (birMonth == '') {
            go = false;
            alert("請選擇出生月");
        }

        //console.log(userPhone);
        //if (userPhone != '') {
        //    var pa_reg = new RegExp("^09[0-9]{8}$", "ig");

        //    var pb_reg = new RegExp("^[0-9]{2,3}-[0-9]{5,8}$", "ig");

        //    if (!pa_reg.exec(userPhone) && !pb_reg.exec(userPhone)) {
        //        go = false;
        //        alert("請輸入正確的手機號碼格式 (EX:0910123456) 或 正確的市內電話格式 (EX：04-22187777)。");
        //    }
        //}

        if (go) {
            const birStr = birYear + '-' + birMonth.padStart(2, "0") + '-01'; //ex: 1911-12-01
            //console.log(birStr);

            API_PUT_EditUser({
                AcntID: JSON.parse(localStorage.getItem("UserData")).acntID,
                AcntTypeID: JSON.parse(localStorage.getItem("UserData")).acntTypeID,
                AcntNo: JSON.parse(localStorage.getItem("UserData")).acntNo,
                AcntName: acntName,
                sub: "",
                UB: {
                    UserIDNo: JSON.parse(localStorage.getItem("UB")).userIDNo,
                    UserPhone: userPhone,
                    CityNo: JSON.parse(localStorage.getItem("UB")).cityNo,
                    AreaNo: JSON.parse(localStorage.getItem("UB")).areaNo,
                    BirDay: birStr,
                    Email: JSON.parse(localStorage.getItem("UB")).email
                }
            }).then((response) => response.data)
                .then((data) => {
                    checkAuthToken(data); //檢查是否逾時
                    //console.log(data.result.acntName);
                    alert("儲存成功");

                    getData();

                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })

            //window.location.reload();
        }
    }

  return (
      <>
          <div id="main" className="" style={{ backgroundColor: "#b8dce8",fontSize: "1.2rem",width: "100%",height: "100vh"}} >
              <div className="container">
                  <div className="row justify-content-center" style={{ paddingTop: "20vh"}} >
                      <div className="col-10" style={{ backgroundColor: "#FFFFFF", padding: "1.5rem"}}>
                          
                          <h2 style={{ textAlign: "center" }}><img src="images/accIcon.svg" style={{width: "3.5rem"}}/>帳號設定</h2>
                          <table className="table">
                              <tbody>
                                  <tr>
                                      <td style={{ width: "180px" }}>使用者名稱</td>
                                      <td><input type="text" className="form-control" value={acntName} onChange={(e) => setAcntName(e.target.value)} /></td>
                                  </tr>
                                  <tr>
                                      <td>電子信箱</td>
                                      <td>
                                          {UB.email}
                                          {/*&emsp;<button type="button" className="btn btn-success btn-sm">已驗證</button>*/}
                                          <br />
                                          <span>這是您用來登入的電子信箱，我們也會發送預訂確認函、批閱完成通知至此信箱。</span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>電話號碼(選填)</td>
                                      <td><input type="text" className="form-control" value={userPhone} onChange={(e) => setUserPhone(e.target.value)} /></td>
                                  </tr>
                                  <tr>
                                      <td>出生年月</td>
                                      <td>
                                          <div className="form-group row">
                                              <div className="col">
                                                  <select id="BirYear" name="BirYear" value={birYear} className="form-control" onChange={(e) => (setBirYear(e.target.value))}>
                                                      <option value="" disabled>請選擇</option>
                                                      {BirYList.map((x) => (
                                                          <option value={x} >{x}</option>
                                                      ))}
                                                  </select>
                                              </div>
                                              <div className="col">
                                                  <select id="BirMonth" name="BirMonth" value={birMonth} className="form-control" onChange={(e) => (setBirMonth(e.target.value))}>
                                                      <option value="" disabled>請選擇</option>
                                                      {BirMList.map((x) => (
                                                          <option value={x} >{x}</option>
                                                      ))}
                                                  </select>
                                              </div>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>

                          {/*<div className="text-right">*/}
                          {/*    <span className="mr-5" style={{ color: "red" }} >預約輔導提醒</span>*/}
                          {/*    <span className="mr-5" style={{ color: "red" }} >2022/07/23  14:00-14:30 </span>*/}
                          {/*    <a href="https://meet.google.com/miv-tsgw-itj">https://meet.google.com/miv-tsgw-itj</a>*/}
                          {/*</div>*/}

                          <div className="signInBtn">
                              <button type="submit" className="btn-ezw-primary btn-block" onClick={upData}>送出</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
     </>
  );
}
 
export default Member;
