import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import "../css/bootstrap.min.css";
import "../js/jquery.min.js";
//import "../js/bootstrap.min.js"; 

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import Header from "../components/partial/header"
import { API_Carousel } from '../global/constants';
function home() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const Code = queryParams.get("Code");

    const [Carousel, setCarousel] = useState([{}]);
    useEffect(() => {
        document.body.style.overflow = "hidden";

        //if (Code == "0000") {
        //    alert("刷卡成功");
        //} else if (Code == "9999" || Code == "2001") {
        //    alert("刷卡失敗");
        //} else if (Code == "1111") {
        //    alert("重複刷卡");
        //}


       /* headerAd();*/
    });
    return (
        <>
            {/*<!-- 1 -->*/}
            <section>
                <div className="container" style={{ paddingTop: "30vh" }}>
                    <div className="row align-items-center">
                        <div className="offset-2 col-7 px-1">
                            {/*<img className="w-100" src="images/index/sign.png" />*/}
                            <img className="w-100" src="images/index/輕鬆寫作吧.svg" style={{ filter: "drop-shadow(rgb(0, 0, 0) 0px 0px 0.3rem)" }} />
                            {/*style={{ filter: "invert(1) sepia(1) brightness(3)" }}*/}
                        </div>
                        <div className="col-xl-3 col-3 px-1">
                            {/*<img className="w-100" src="images/index/slogan.png" />*/}
                            <img className="w-100" src="images/index/寫作得高分一點也不難.svg" />
                        </div>

                    </div>
                    <div className="row justify-content-center align-items-center" style={{ marginTop: "7rem" }}>
                        <div className="col-xl-10 col-12">
                            <img className="w-100" src="images/index/從普通到資優寫作真的很簡單.svg" />
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- 2 -->*/}
            <section>
                <div className="container" style={{ paddingTop: "20vh" }}>
                    <div className="row justify-content-center">
                        {/*  <!-- imgLink -->*/}
                        <div className="col-3 text-center">
                            <a href="/About"><img src="images/index/about.png" className="note" /></a>
                        </div>
                        <div className="col-3 text-center">
                            <a href="/Service"><img src="images/index/service.png" className="note" /></a>
                        </div>
                        <div className="col-3 text-center">
                            <a href="#sys_signin" data-toggle="modal"><img src="images/index/login.png" className="note" /></a>
                        </div>
                    </div>
                </div>

                <div className="text-center" style={{ paddingTop: "15vh" }} >
                    <img className="we-hope-you" src="images/index/we_hope_you.png" />
                </div>
            </section>

            {/*<!-- 3 -->*/}
            <section>
                <div className="container">
                    <div className="row" style={{ paddingTop: "25vh" }} >
                        <div className="col-6">
                            <img className="ezw1" src="images/index/輕鬆寫作.svg" />
                        </div>
                        <div className="col-6 offset-6 text-left">
                            <img className="ezw1_1 pt-5" src="images/index/5.png" />
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- 4 -->*/}
            <section>
                <div className="container">
                    <div className="row" style={{ paddingTop: "10vh" }} >
                        <div className="col-6 offset-6 text-right">
                            <img className="ezw1 pb-5 mb-5" src="images/index/輕鬆寫作.svg" />
                        </div>
                        <div className="col-6">
                            <img className="ezw1_1 pt-5 mt-5" src="images/index/4.png" />
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- 5 -->*/}
            <section>
                <div className="container">
                    <div className="row" style={{ paddingTop: "35vh" }}>
                        <div className="col-6">
                            <img className="ezw1" src="images/index/輕鬆寫作.svg" />
                        </div>
                        <div className="col-6 text-right">
                            <img style={{ paddingTop: "8rem" }} className="ezw3_1" src="images/index/3.png" />
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- 6 -->*/}
            <section>
                <div className="container">
                    <div className="row" style={{ paddingTop: "20vh" }}>
                        <div className="col-6 offset-6 text-right">
                            <img className="ezw1" src="images/index/輕鬆寫作.svg" />
                        </div>
                        <div className="col-6 text-left">
                            <img style={{ width: "100%" }} src="images/index/2.png" />
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- 7 -->*/}
            <section>
                <div className="container">
                    <div className="row" style={{ paddingTop: "30vh" }}>
                        <div className="col-6">
                            <img className="ezw1" src="images/index/輕鬆寫作.svg" />
                        </div>
                        <div className="col-6 offset-6 text-right">
                            <a data-toggle="modal" href="#sys_signin">
                                <img className="ezw1_1 pt-5 mt-5" src="images/index/1.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- 8 -->*/}
            <section>
                <div className="container">
                    <div className="row text-center justify-content-center" style={{ paddingTop: "40vh" }}>
                        <div className="col-8">
                            <img src="images/index/now_start.png" className="w-100" />
                            {/*<img src="images/index/31營運.png" className="w-100" />*/}
                        </div>
                        <div className="col-12 px-5" style={{ paddingTop: "30vh" }}>
                            <p style={{ color: "#4F4F4F" }}>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407040臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500 分機815　客服信箱：service@writingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}


export default home;
