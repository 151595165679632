import "../css/style.css";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//import { API_GET_??? } from '../global/constants';
import { FormattedMessage } from "react-intl"; //多國語言套件

function Ideal() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

    const [takPlain, setTakPlain] = useState('');
    const [historyTable, setHistoryTable] = useState([
        {
            "testYear": "111年會考",
            "testTitle": "多做多得",
            "testHref": "https://cap.rcpet.edu.tw/exam/111/111P_Writing.pdf",
            "getScore6":"111writing-6.pdf",
            "getScore5":"111writing-5.pdf",
        },
        {
            "testYear": "110年會考",
            "testTitle": "未成功的物品展覽會",
            "testHref": "https://cap.rcpet.edu.tw/exam/110/110P_Writing.pdf",
            "getScore6": "110writing-6.pdf",
            "getScore5": "110writing-5.pdf",
        },
        {
            "testYear": "109年會考",
            "testTitle": "我想開設一家這樣的店",
            "testHref": "https://cap.rcpet.edu.tw/exam/109/109P_Writing150DPI.pdf",
            "getScore6": "109writing-6.pdf",
            "getScore5": "109writing-5.pdf",
        },
        {
            "testYear": "108年會考",
            "testTitle": "青銀共居",
            "testHref": "https://cap.rcpet.edu.tw/exam/108/108P_Writing150DPI.pdf",
            "getScore6": "108writing-6.pdf",
            "getScore5": "108writing-5.pdf",
        },
        {
            "testYear": "107年會考",
            "testTitle": "我們這個世代",
            "testHref": "https://cap.rcpet.edu.tw/exam/107/107P_Writing.pdf",
            "getScore6": "107writing-6.pdf",
            "getScore5": "107writing-5.pdf",
        },
        {
            "testYear": "106年會考",
            "testTitle": "在這樣的傳統習俗裡，我看見……",
            "testHref": "https://cap.rcpet.edu.tw/exam/106/106P_Writing150DPI.pdf",
            "getScore6": "106writing-6.pdf",
            "getScore5": "106writing-5.pdf",
        },
        {
            "testYear": "105年會考",
            "testTitle": "從陌生到熟悉",
            "testHref": "https://cap.rcpet.edu.tw/exam/105/105P_Writing150DPI.pdf",
            "getScore6": "105writing-6.pdf",
            "getScore5": "105writing-5.pdf",
        },
        {
            "testYear": "104年會考",
            "testTitle": "捨不得",
            "testHref": "https://cap.rcpet.edu.tw/exam/104/104P_Writing.pdf",
            "getScore6": "104writing-6.pdf",
            "getScore5": "104writing-5.pdf",
        },
        {
            "testYear": "103年會考",
            "testTitle": "面對未來，我應該具備的能力",
            "testHref": "https://cap.rcpet.edu.tw/exam/103/103P_Writing.pdf",
            "getScore6": "103writing-6.pdf",
            "getScore5": "103writing-5.pdf",
        },
        {
            "testYear": "102年試辦會考",
            "testTitle": "從那件事中，我發現了不一樣的自己",
            "testHref": "https://cap.rcpet.edu.tw/exam/102/102P_Writing150DPI.pdf",
            "getScore6": "102writing-6.pdf",
            "getScore5": "102writing-5.pdf",
        },
    ]);

    const [versionRadio, SetVersionRadio] = useState('康軒');
    const [practiceTable, setPracticeTable] = useState([
        {
            "parentVersion": "康軒",
            "testYear": "111年(4)",
            "testTitle": "從一間老屋的重生，我看到……",
            "testHref": "K111-4.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "111年(3)",
            "testTitle": "",
            "testHref": "",
        },
        {
            "parentVersion": "康軒",
            "testYear": "111年(2)",
            "testTitle": "為自己學習一堂線上課程",
            "testHref": "K111-2.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "111年(1)",
            "testTitle": "我的星期○印象",
            "testHref": "K111-1.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "110年(6)",
            "testTitle": "如果用一張照片自製明信片",
            "testHref": "K110-6.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "110年(5)",
            "testTitle": "九年級是否要開設本土語文課程",
            "testHref": "K110-5.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "110年(4)",
            "testTitle": "在疫情警戒升級期間，對於遠距教學的感受或想法",
            "testHref": "K110-4.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "110年(3)",
            "testTitle": "在這件事上，我做出了改變",
            "testHref": "K110-3.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "110年(2)",
            "testTitle": "那一次，我很自責",
            "testHref": "K110-2.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "110年(1)",
            "testTitle": "我從忽略到關心的一件事",
            "testHref": "K110-1.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "109年(6)",
            "testTitle": "你對於玩電玩遊戲有什麼樣的想法？",
            "testHref": "K109-6.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "109年(5)",
            "testTitle": "我未來最想定居的城市",
            "testHref": "K109-5.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "109年(4)",
            "testTitle": "一條令我嚮往的街道",
            "testHref": "K109-4.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "109年(3)",
            "testTitle": "那一次團隊合作中，我領悟到……",
            "testHref": "K109-3.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "109年(2)",
            "testTitle": "做自己的情緒總管",
            "testHref": "K109-2.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "109年(1)",
            "testTitle": "那一次，我據理力爭或妥協包容",
            "testHref": "K109-1.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "108年(6)",
            "testTitle": "預約十年後的自己",
            "testHref": "K108-6.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "108年(5)",
            "testTitle": "你對於自拍有什麼樣的想法？",
            "testHref": "K108-5.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "108年(4)",
            "testTitle": "我們這個時代的聲音",
            "testHref": "K108-4.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "108年(3)",
            "testTitle": "你對國中生補習的必要性，有什麼想法？",
            "testHref": "K108-3.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "108年(2)",
            "testTitle": "我如何面對生活中的壓力",
            "testHref": "K108-2.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "108年(1)",
            "testTitle": "回到沒有____的生活，會更好",
            "testHref": "K108-1.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "107年(6)",
            "testTitle": "國中這趟旅程",
            "testHref": "K107-6.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "107年(5)",
            "testTitle": "適可而止",
            "testHref": "K107-5.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "107年(4)",
            "testTitle": "遺失一件物品之後",
            "testHref": "K107-4.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "107年(3)",
            "testTitle": "我看促銷活動",
            "testHref": "K107-3.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "107年(2)",
            "testTitle": "最令我遺憾的一件事",
            "testHref": "K107-2.pdf",
        },
        {
            "parentVersion": "康軒",
            "testYear": "107年(1)",
            "testTitle": "改變的力量",
            "testHref": "K107-1.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "111年(4)",
            "testTitle": "",
            "testHref": "",
        },
        {
            "parentVersion": "翰林",
            "testYear": "111年(3)",
            "testTitle": "",
            "testHref": "",
        },
        {
            "parentVersion": "翰林",
            "testYear": "111年(2)",
            "testTitle": "",
            "testHref": "",
        },
        {
            "parentVersion": "翰林",
            "testYear": "111年(1)",
            "testTitle": "",
            "testHref": "",
        },
        {
            "parentVersion": "翰林",
            "testYear": "110年(6)",
            "testTitle": "我看人類生活的變化",
            "testHref": "H110-6.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "110年(5)",
            "testTitle": "如果每天多出一個小時",
            "testHref": "H110-5.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "110年(4)",
            "testTitle": "其實我有話想說",
            "testHref": "H110-4.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "110年(3)",
            "testTitle": "「從那件事，我領悟了『過猶不及』的道理",
            "testHref": "H110-3.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "110年(2)",
            "testTitle": "「在____裡，看見我的堅持",
            "testHref": "H110-2.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "110年(1)",
            "testTitle": "遠距教學，我是____型",
            "testHref": "H110-1.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "109年(6)",
            "testTitle": "祈願卡上，我想寫下……",
            "testHref": "H109-6.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "109年(5)",
            "testTitle": "因為你，我學會了____",
            "testHref": "H109-5.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "109年(4)",
            "testTitle": "年輕畫家會這麼說",
            "testHref": "H109-4.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "109年(3)",
            "testTitle": "你對老師管教學生的模式或方法，有什麼期待？",
            "testHref": "H109-3.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "109年(2)",
            "testTitle": "一款讓生活更便利的電腦應用程式",
            "testHref": "H109-2.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "109年(1)",
            "testTitle": "我想成為這樣的人物",
            "testHref": "H109-1.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "108年(6)",
            "testTitle": "為什麼要助人〉或〈如何助人的做法或條件",
            "testHref": "H108-6.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "108年(5)",
            "testTitle": "再多一點____，就會更好",
            "testHref": "H108-5.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "108年(4)",
            "testTitle": "與科技同行",
            "testHref": "H108-4.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "108年(3)",
            "testTitle": "不容忽視的小細節",
            "testHref": "H108-3.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "108年(2)",
            "testTitle": "我和目標的距離",
            "testHref": "H108-2.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "108年(1)",
            "testTitle": "我對電子商務、使用網路進行交易，有什麼期待?",
            "testHref": "H108-1.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "107年(6)",
            "testTitle": "我表達想法的方式",
            "testHref": "H107-6.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "107年(5)",
            "testTitle": "沒想到",
            "testHref": "H107-5.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "107年(4)",
            "testTitle": "與人相處的重要法則",
            "testHref": "H107-4.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "107年(3)",
            "testTitle": "寫作教會我的事",
            "testHref": "H107-3.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "107年(2)",
            "testTitle": "我所嚮往的風土民情",
            "testHref": "H107-2.pdf",
        },
        {
            "parentVersion": "翰林",
            "testYear": "107年(1)",
            "testTitle": "我和他之間",
            "testHref": "H107-1.pdf",
        },
    ]);

    useEffect(() => {
        let params = new URLSearchParams(window.location.search),
            urltak = params.get('exam');
        if (urltak) {
            setTakPlain(urltak);
        }
      /*if (localStorage.getItem("UserData") != null) {
          setUserData(JSON.parse(localStorage.getItem("UserData")));
      }*/
  }, []);
    function whichVersion(e) {
        SetVersionRadio(e);
        console.log(e)
    }
  return (
      <>
          <div className="top"></div>
          <div id="main" className="" style={{
              backgroundColor: "rgb(255,222,212)",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              fontSize: "1.2rem",
              width: "100%",
              height: "100vh",
              position: "relative",
              padding: "0 calc(50vw - 500px) 0",
              overflow: "auto",
              overflowX: "hidden"
          }} >
              <div className="row mx-0 mx-md-5 justify-content-center align-content-start" style={{ backgroundColor: "#FFF", margin: "0", minHeight: "100vh", paddingTop: "15vh", paddingBottom: "36px", overflow: "auto" }}>
                  <div className="col-md-11 col-12 text-center">
                      <h1 className="text-info h2">
                          {(takPlain == ""
                          ?
                          "寫作測驗試題及評分規準調整說明"
                          :
                          (takPlain == "history"
                              ?
                              "歷屆會考寫作測驗試題"
                              :
                              "國中會考模擬考寫作測驗試題"
                          ))}
                      </h1>
                      <p className="text-dark">
                          {(takPlain == ""
                              ?
                              "（參考資料來源：國中教育會考網站）"
                              :
                              (takPlain == "history"
                                  ?
                                  "（參考資料來源：國中教育會考網站）"
                                  :
                                  ""
                              ))}
                      </p>
                  </div>
                  {/*測驗評分規準*/}
                  {(takPlain == "" ?
                      <div className="col-md-8 col-8 text-center justify-content-start" >
                          <a className="p-0 my-4 d-block" target="blank" href="https://cap.rcpet.edu.tw/exam/111c/111%E5%B9%B4%E6%95%99%E8%82%B2%E6%9C%83%E8%80%83%E5%AF%AB%E4%BD%9C%E6%B8%AC%E9%A9%97%E8%A9%A6%E9%A1%8C%E5%8F%8A%E8%A9%95%E5%88%86%E8%A6%8F%E6%BA%96%E8%AA%BF%E6%95%B4%E8%AA%AA%E6%98%8E.pdf">網站連結</a>
                          <img src="images/about/bg-walkout.png" width="100%" height="auto" alt="前往其他連結" />
                      </div>
                      :
                      ""
                  )}

                  {/*歷屆會考試題*/}
                  {(takPlain == "history" ?
                      <div className="col-md-11 col-12 text-center justify-content-start">
                          <table className="table table-striped my-2">
                              <thead>
                                  <tr className="text-white" style={{ backgroundColor: "#585858", borderLeft: "1px solid #585858", borderRight: "1px solid #585858" }} >
                                      <th style={{ borderRight: "1px solid #fff", borderBottom: "1px solid #585858" }} >年&nbsp; &nbsp; 度</th>
                                      <th style={{ borderRight: "1px solid #fff", borderBottom: "1px solid #585858" }} >作&nbsp; &nbsp; 文&nbsp; &nbsp; 題&nbsp; &nbsp; 目</th>
                                      <th style={{ whiteSpace: "nowrap", verticalAlign: "middle", borderRight: "1px solid #fff", borderBottom: "1px solid #585858" }} >六級分樣卷</th>
                                      <th style={{ whiteSpace: "nowrap", verticalAlign: "middle", borderBottom: "1px solid #585858" }}>五級分樣卷</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {historyTable.map((value, i) => (
                                      <tr key={i} >
                                          <td style={{ verticalAlign: "middle", border: "1px solid #8a8a8a" }} >{value.testYear}</td>
                                          <td style={{ verticalAlign: "middle", textAlign: "left", border: "1px solid #8a8a8a" }} >
                                              <a className="text-dark" href={value.testHref} target="blank">〈{value.testTitle}〉</a></td>
                                          <td style={{ verticalAlign: "middle", border: "1px solid #8a8a8a" }} >
                                              <a className="btn-ezw-primary font-weight-normal py-0 rounded" type="button" href={'/file/history/' + value.getScore6} target="blank">下載</a></td>
                                          <td style={{ verticalAlign: "middle", border: "1px solid #8a8a8a" }} >
                                              <a className="btn-ezw-primary font-weight-normal py-0 rounded" type="button" href={'/file/history/' + value.getScore5} target="blank">下載</a></td>
                                      </tr>
                                  ))}
                                  <tr>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      :
                      ""
                  )}

                  {/*模擬考試題*/}
                  {(takPlain == "practice" ?
                      <div className="col-md-11 col-12 text-center justify-content-start" >
                          <div className="col-md-11 col-12 text-right">
                              <lable htmlFor="versionRadio1" className={(versionRadio == "康軒" ? "btn-ezw-primary mx-3" : "btn-ezw-outline-primary mx-3")} onClick={(e) => whichVersion("康軒")}>
                                  康軒
                                  <input id="versionRadio1" type="radio" className="d-none" value="康軒" onChange={whichVersion} />
                              </lable>
                              <lable htmlFor="versionRadio2" className={(versionRadio == "翰林" ? "btn-ezw-primary" : "btn-ezw-outline-primary")} onClick={(e) => whichVersion("翰林")}>
                                  翰林
                                  <input id="versionRadio2" type="radio" className="d-none" value="翰林" onChange={whichVersion} />
                              </lable>
                          </div>
                          <div className="col-md-11 col-12 text-center">
                              <table className="table table-striped my-2">
                                  <thead>
                                      <tr className="text-white" style={{ backgroundColor: "#585858", borderLeft: "1px solid #585858", borderRight: "1px solid #585858" }} >
                                          <th style={{ borderRight: "1px solid #fff", borderBottom: "1px solid #585858" }} >年&nbsp; &nbsp; 度</th>
                                          <th style={{ borderRight: "1px solid #fff", borderBottom: "1px solid #585858" }} >作&nbsp; &nbsp; 文&nbsp; &nbsp; 題&nbsp; &nbsp; 目</th>
                                          <th style={{ whiteSpace: "nowrap", verticalAlign: "middle", borderBottom: "1px solid #585858" }} >試題下載</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {practiceTable.map((value, i) => {
                                          if (value.parentVersion != versionRadio) return null;
                                          if (!value.testHref) return null;
                                          return (
                                              <tr key={i} >
                                                  <td style={{ verticalAlign: "middle", border: "1px solid #8a8a8a" }} >
                                                      {value.testYear}
                                                  </td>
                                                  <td style={{ verticalAlign: "middle", textAlign: "left", border: "1px solid #8a8a8a" }} >
                                                      〈{value.testTitle}〉
                                                  </td>
                                                  <td style={{ verticalAlign: "middle", border: "1px solid #8a8a8a" }} >
                                                      {(value.testTitle
                                                          ?
                                                          <a className="btn-ezw-primary font-weight-normal py-0 rounded" type="button" href={'/file/practice/' + value.testHref} target="blank">下載</a>
                                                          :
                                                          "")}
                                                  </td>
                                              </tr>
                                          )
                                      }
                                      )}
                                      <tr>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      ""
                  )}

              </div>

              <div className="footer pt-4">
                  <div class="row text-center justify-content-center">
                      <div class="col-12">
                          <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407040臺中市西屯區長安路一段83號5樓<br />
                              電話：(04)2315-2500 分機815　客服信箱：service@writingbar.com.tw</p>
                      </div>
                  </div>
              </div>
          </div>
     </>
    );
}
 
export default Ideal;
